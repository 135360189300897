import { useContext, useEffect, useState } from "react";
import { UserProfileContext } from "./UserContext";

export const useGetState = () => {
  const { state, dispatch, usersData } =
    useContext(UserProfileContext);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(state);
    // eslint-disable-next-line
  }, [state?.userInfo]);

  return { state: userInfo, dispatch, usersData };
};
