import React from 'react'
import {Flex, Text } from '@chakra-ui/react'


const AmountCard = ({ title, amount, color, bg }) => {
  return (
    <Flex
      bg={bg || "#fff"}
      borderRadius={"10px"}
      height={"100%"}
      flexDirection={["row", "row", "column"]}
      justifyContent={"space-between"}
      p="20px"
      mt={["20px", "20px", "0px"]}

    >
      <Text color="#33035E" fontWeight={"bold"} fontSize={"40px"}>
        {amount}
      </Text>
      <Text color={color || "#33035E"}>{title}</Text>
    </Flex>
  );
}

export default AmountCard
