// import { errorNotifier } from "..";
import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getPendingLoanRepayments = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_PENDING_REPAYMENTS);
    setLoading(false);
    setData(data);
    // return data;
  } catch (e) {
    setLoading(false);
    // console.log(e?.response);
    // errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

export const getCompletedLoanRepayments = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_COMPLETED_REPAYMENTS);
    setLoading(false);
    setData(data);
  } catch (e) {
    setLoading(false);
    // console.log(e?.response);
  }
};

export const getDueLoanRepayments = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_OVERDUE_REPAYMENT);
    setLoading(false);
    setData(data);
  } catch (e) {
    setLoading(false);
    // console.log(e?.response);
  }
};
export const getSingleLoan = async (setData, setLoading, loanId) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_SINGLE_LOAN(loanId));
    setLoading(false);
    setData(data);
  } catch (e) {
    setLoading(false);
    // console.log(e?.response);
  }
};

export const searchAllLoanRepayments = async (setData, setLoading, keyword) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.SEARCH_ACTIVE_LOAN_REPAYMENTS(keyword));
    setLoading(false);
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

// export const inititiateDebit = async (payload) => {
//   try {
//     const { data } = await http.post(AUTH_ROUTES.INITIATE_DIRECT_DEBIT, payload);
//     if (data.success && data?.data && data?.data?.data?.authorizationUrl) {
//       window.location.href = data?.data?.data?.authorizationUrl;
      
//       // window.open(data?.data?.data?.authorizationUrl, "_blank");
//       successNotifier("Transaction initiated successfully");


//       console.log(data,"TransINIT")
//     } else {
//       errorNotifier("Failed to initiate transaction");
//     }
//   } catch (e) {
//     if (e?.response) {
//       errorNotifier(
//         console.log(
//           e.response?.data?.message || e.response?.data?.data?.message
//         )
//       );
//     }
//     //  else {
//     //   errorNotifier("Network Error, please check your internet connections");
//     // }
//   }
// };

export const inititiateDebit = async (payload,setLoading,navigate) => {
  try {
    const { data } = await http.post(
      AUTH_ROUTES.INITIATE_DIRECT_DEBIT,
      payload
    );
    setLoading(false)
    successNotifier("Debit Successful");
    navigate("/repayment-verification", { state: {data} })
    // setRefresh((prev) => !prev);
    return data;
  } catch (e) {
    setLoading(false)
    if (e?.response) {
      errorNotifier(
        console.log(
          e.response?.data?.message || e.response?.data?.data?.message
        )
      );
    } else {
      errorNotifier("Network Error, please check your internet connections");
    }
  }
};

export const verifyRepayment = async (payload,setLoading,navigate) => {
  try {
    const { data } = await http.post(
      AUTH_ROUTES.VERIFY_REPAYMENT,
      payload
    );
    setLoading(false)
    successNotifier("Verification Successful");
    // navigate("/", { state: {data} })
    navigate("/loan-repayments")
    // setRefresh((prev) => !prev);
    return data;
  } catch (e) {
    setLoading(false)
    if (e?.response) {
      errorNotifier(
        console.log(
          e.response?.data?.message || e.response?.data?.data?.message
        )
      );
    } else {
      errorNotifier("Network Error, please check your internet connections");
    }
  }
};


export const searchLoanRepaymentsByDates = async (
  setData,
  setLoading,
  startDate,
  endDate,
  stat,
  status
) => {
  try {
    const {
      data: { data },
    } = await http.get(
      AUTH_ROUTES.SEARCH_ACTIVE_LOAN_REPAYMENTS_BY_DATES(
        startDate,
        endDate,
        stat,
        status
      )
    );
    setLoading(false);
    // console.log(data, "LOANS FOUND");
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const disburseApprovedLoans = async (loanId, setLoading, setRefresh) => {
  try {
    await http.put(AUTH_ROUTES.DISBURSE_LOAN(loanId));
    setLoading(false);
    // console.log(data, "LOAN DISBURSED");
    successNotifier("Loan Disbursed Successfully");
    setRefresh((prev) => !prev);
    // return data;
  } catch (e) {
    setLoading(false);
    // console.log(e?.response);
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

// export const initiateTransaction = async (payload, setLoading, navigate) => {
//   try {
//     const { data } = await http.post(AUTH_ROUTES.INITIATE_TRANSACTIONS, payload);
//     if (data.success && data?.data && data?.data?.data?.authorizationUrl) {
//       window.location.href = data?.data?.data?.authorizationUrl;
      
//       // window.open(data?.data?.data?.authorizationUrl, "_blank");
//       successNotifier("Transaction initiated successfully");
//       setLoading(false)
//       // navigate('/signup');
//       // sessionStorage.removeItem("#TECH_LEARN#COMP");
//       // window.location.reload();

//       console.log(data,"TransINIT")
//     } else {
//       errorNotifier("Failed to initiate transaction");
//     }
//   } catch (e) {
//     if (e?.response) {
//       setLoading(false)
//       errorNotifier(
//         console.log(
//           e.response?.data?.message || e.response?.data?.data?.message
//         )
//       );
//     }
//     //  else {
//     //   errorNotifier("Network Error, please check your internet connections");
//     // }
//   }
// };

