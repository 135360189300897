import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { formatToNaira } from "../../utils/numberFormat";
import CustomDrawer from "../Drawer";
import MessageBox from "../messageInfo/MessageBox";
import { useState } from "react";
import { CustomBtn } from "../CustomBtn";
import { inititiateDebit } from "../../services/loanRepayments";
import { useNavigate } from "react-router-dom";
// import { QueryClient, useMutation } from "@tanstack/react-query";

const LoanRepaymentsDrawerContent = ({ datum }) => {
  const navigate = useNavigate()
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState()
  console.log(datum, "messssse")
  // const queryClient = new QueryClient();

  // const { mutate: initiatePayment, isPending: isMutating } = useMutation({
  //   mutationFn: inititiateDebit,
  //   mutationKey: ["initiate-payment"],
  //   onSuccess: (data) => {
  //     console.log("init payment data", data?.data?.data);
  //     if (data?.data?.data?.authorizationUrl) {
  //       window.location.href = data?.data?.data?.authorizationUrl;
  //     }
  //     // queryClient.invalidateQueries({ queryKey: ["subscriptions"] });
  //     //  onClose();
  //   },
  //   onError: (error) => {
  //     console.error("Create Customer Mutation error", error);
  //   },
  // });
  const money = Math.round(datum?.totalAmountDue)
  console.log(money,"MONEY")
  
  const handleInitializePayment =  async (e) => {
    e.preventDefault()
    setLoading(true)
    const payload = {
      authorizationCode: datum?.directDebitDetails?.authorizationCode,
      amount: money,
      email: datum?.userId[0]?.email,
      loanId: datum?._id,
      callbackUrl: `${window.location.href}`,
    };
    console.log("PAYLOAD", payload);
    await inititiateDebit(payload, setLoading, navigate);
  };








  return (
    <>
      <Flex
        width="100%"
        justify={"space-between"}
        mb="20px"
        mt="40px"
        px="30px !important"
      >
        <Text color="#373737" fontWeight={"semibold"}>
          Recent Report
        </Text>
        <Text
          textTransform="capitalize"
          bg={
            datum?.status?.toLowerCase() === "pending"
              ? "#FBF3D4"
              : datum?.status?.toLowerCase() === "completed"
              ? "green.300"
              : "red"
          }
          color={
            datum?.status?.toLowerCase() === "pending" ? "#C9A721" : "#FFFFFF"
          }
          p="2px 10px"
          borderRadius={"10px"}
        >
          {datum?.status}
        </Text>
      </Flex>

      <Box p="20px 30px" bg="#F8F8F8">
        <Flex width="100%" justify={"space-between"} fontSize={"14px"}>
          <Stack color="#B9B7B7" spacing={"12px"}>
            <Text>Request Date</Text>
            <Text>Amount Received</Text>
            <Text>Interest Amount</Text>
            <Text>Due Date</Text>
            {/* <Text>First Payment Amount</Text>
            <Text>Second Payment Amount</Text> */}
            <Text>Total Amount Paid</Text>
          </Stack>
          <Stack color="#04000A" spacing={"12px"}>
            <Text color="#767677">
              {dayjs(datum?.createdAt).format("MMM DD, YYYY")}
            </Text>
            <Text >{datum?.amount && formatToNaira(datum?.amount)}</Text>
            <Text>
            {datum?.totalAmountDue
                ? formatToNaira(datum?.totalAmountDue)
                : "Not Available"}
            </Text>
            <Text color="#767677">
            {datum?.loanDisbursed &&
                dayjs(datum?.dueDate).format("DD MMM YYYY")}
            </Text>
            {/* <Text>
              {datum?.payment && formatToNaira(datum?.firstPayment)}
              {datum?.firstPaymentDAte
                ? `(${dayjs(datum?.firstPaymentDAte).format("MMM DD, YYYY")})`
                : "N/A"}
            </Text>
            <Text>
              {formatToNaira(datum?.secondPayment)}
              {datum?.secondPaymentDate
                ? `(${dayjs(datum?.secondPaymentDate).format("MMM DD, YYYY")})`
                : "N/A"}
            </Text> */}
            <Text>
            {formatToNaira(datum?.amountRepaid) || formatToNaira("0")}
            </Text>
          </Stack>
        </Flex>
      </Box>

      <Flex p="30px" width={"100%"} justify="space-between" align={"center"}>
        <CustomBtn disabled={datum?.repaymentStatus==="completed"} loading={loading} handleClick={handleInitializePayment} text={"Deduct From Account"} bg={"blue.500"} />
        <CustomDrawer
          button={true}
          header={"Individual Message"}
          iconColor={"#5E6CE7"}
          // data={data}
        >
          <MessageBox
            recipient={[datum]}
            selectedItems={[datum?.userId[0]?._id]}
            types={types}
            setTypes={setTypes}
          />
        </CustomDrawer>
      </Flex>
    </>
  );
};

export default LoanRepaymentsDrawerContent;
