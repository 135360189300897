import React from "react";
import { Box, Table } from "@chakra-ui/react";
import TableHead from "./TableHead";

const CustomTable = ({ tableHeadData, children }) => {
  return (
    <Box className="table-container" overflowX="scroll" width={"100%"}>
      <Table
        width="100% !important"
        variant="striped"
        colorScheme="gray"
        borderRadius={"20px"}
      >
        <TableHead tableHeadData={tableHeadData} />
        {children}
      </Table>
    </Box>
  );
};

export default CustomTable;
