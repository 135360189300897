import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
} from "@chakra-ui/react";
import { useGetState } from "../contexts/useGetState";
import { BiMenu } from "react-icons/bi";

const NavBar = ({ showSidebar, setShowSidebar, setTabWidth }) => {
  const { state } = useGetState();
  return (
    <Flex
      width={"100%"}
      bg="#F9F9F9"
      p={["20px 25px", "20px 40px", "20px 40px"]}
      color={"#1A0031"}
      justifyContent="space-between"
      alignItems="center"
      textAlign={"center"}
    >
      <Box>
        {window.innerWidth > 1100 ? (
          <Flex justifyContent={"space-between"} w="100%">
            <Heading as="h2" fontSize={["1.6rem"]}>
              Welcome {state?.userInfo?.name}
            </Heading>
          </Flex>
        ) : (
          <BiMenu
            size={24}
            cursor={"pointer"}
            onClick={() => {
              setShowSidebar(true);
              setTabWidth("240px");
            }}
          />
        )}
      </Box>
      <Box className="logo" textAlign={"left"}>
        <Flex justify={"center"} align="center">
          <Box mr="10px">
            <Image
              width="40px"
              height="50px"
              src={state?.userInfo?.image}
              alt=""
              borderRadius={"10px"}
            />
          </Box>
          <Box>
            <Text fontSize={"16px"}>{state?.userInfo?.email}</Text>
            <Text fontSize={"16px"} fontWeight="semibold">
              Administrator
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default NavBar;
