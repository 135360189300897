import { Button, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BsThreeDots } from 'react-icons/bs'
import PropTypes from "prop-types";
import CustomModal from './CustomModal';
import ApproveLoan from './ApproveLoan';
import VerifyConsent from './VerifyConsent';
import { consentReminder } from '../services/loanReports';

export const MoreActionButton = ({datum, setRefresh, onClose}) => {
    console.log("dhbvfe", datum)
    // const [loading, setLoading] = useState(false)
    const [formData, setFormData]= useState({
      email:datum?.userId[0]?.email
    })

    const handleSendConsent = async(e) =>{
      e.preventDefault()
      const payload =  {...formData}
        // setLoading(true);
        await consentReminder(payload, setRefresh, onClose );
    }

  return (
    <Popover>
    <PopoverTrigger>
      <Button
        bg="transparent"
        _hover={{ background: "transparent" }}
        _focus={{ background: "transparent" }}
      >
        <BsThreeDots fontSize={"1.2em"} color={"red"} />
      </Button>
    </PopoverTrigger>
    <PopoverContent w={"100%"}>
      <PopoverArrow />
      <PopoverBody gap="10px" display={"flex"} flexDir={"column"}>
        {!datum?.directDebitDetails?.active && (
           <Text fontSize={"13px"} onClick={handleSendConsent} cursor={"pointer"}>Send Consent Reminder</Text>
        )}
         <CustomModal
              header="Verify Loan"
              icon={<Text fontSize={"13px"} cursor="pointer">Verify Loan</Text>}
            >
              <ApproveLoan datum={datum} setRefresh={setRefresh} onClose={onClose}/>
            </CustomModal>
            {!datum?.directDebitDetails?.active===true && (
               <CustomModal
              header="Verify Consent"
              icon={<Text fontSize={"13px"} cursor="pointer">Confirm Consent</Text>}
            >
              <VerifyConsent datum={datum} setRefresh={setRefresh} onClose={onClose}/>
            </CustomModal>
            )}
           
        
          <>
            {/* <CustomModal
              header="Assign Request"
              icon={<Text cursor="pointer">Assign Request</Text>}
            >
              <AssignPersonnel requestId={requestId} />
            </CustomModal> */}
            {/* <CustomModal
              header="Make This Request Public"
              icon={<Text cursor="pointer">Make Public</Text>}
            >
              <CautionAlertDialogue
                continueText="Yes"
                // loading={isUpdatemutating}
                // handleContinue={handleMakeRequestPublic}
                text="Are you sure you want to make this request public?"
              />
            </CustomModal> */}
          </>
      </PopoverBody>
    </PopoverContent>
  </Popover>
  )
}

MoreActionButton.propTypes = {
    clickRef: PropTypes.func.isRequired,
    loanId: PropTypes.string,
    // status: PropTypes.string,
  };
  