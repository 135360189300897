import React, { useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineEdit } from "react-icons/ai";
import { updateAdmin } from "../../services/message";

export const EditAdminDrawer = ({ setRefresh, data }) => {
  const [loading, setLoading] = useState(false);
  const [adminType, setAdminType] = useState(data?.adminType);
  const [name, setName] = useState(data?.name);
  const [email, setEmail] = useState(data?.email);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      name,
      email,
      adminType,
    };

    updateAdmin(payload, setLoading, setRefresh, data._id, onClose);
  };
  return (
    <>
      <AiOutlineEdit color="#5E6CE7" onClick={onOpen} />
      <Drawer
        size={"lg"}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          {/* <DrawerCloseButton /> */}
          <Flex
            width="100%"
            align={"center"}
            justify={"space-between"}
            boxShadow="md"
            p="20px 30px !important"
          >
            <Text fontSize={"20px"} color={"#032A34"} fontWeight="semibold">
              Edit Existing Admin
            </Text>
            <Button
              bg="#5E6CE7"
              px={"30px"}
              color={"#fff"}
              borderRadius="25px"
              ref={btnRef}
              onClick={onClose}
              _hover={{
                bg: "#5E6CE7",
              }}
            >
              Cancel
            </Button>
          </Flex>

          <DrawerBody p="20px 30px !important">
            <Flex justify={"space-between"} align={"center"} w={"100%"}>
              <Text color={"#373737"} fontWeight={"semibold"} fontSize={"18px"}>
                Admin Personal Information
              </Text>
            </Flex>

            <Flex
              w={"100%"}
              mt={"50px"}
              direction={["column", "column", "row"]}
            >
              <FormControl mr={["0px", "0px", "30px"]}>
                <FormLabel color={"#373737"} fontWeight={400} fontSize={"16px"}>
                  First Name
                </FormLabel>
                <Input
                  type="text"
                  bg={"#FFFFFF"}
                  h={"50px"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Flex>

            <Flex
              w={"100%"}
              mt={["20px", "20px", "30px"]}
              mb={["50px"]}
              direction={["column", "column", "row"]}
            >
              <FormControl mr={["0px", "0px", "30px"]}>
                <FormLabel color={"#373737"} fontWeight={400} fontSize={"16px"}>
                  Email Address
                </FormLabel>
                <Input
                  type="email"
                  bg={"#FFFFFF"}
                  h={"50px"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl mr={["0px", "0px", "30px"]}>
                <FormLabel color={"#373737"} fontWeight={400} fontSize={"16px"}>
                  Admin Type
                </FormLabel>
                <Select
                  placeholder="Select Admin Type"
                  bg={"#FFFFFF"}
                  h={"50px"}
                  value={adminType}
                  onChange={(e) => setAdminType(e.target.value)}
                >
                  <option value="superAdmin">superAdmin</option>
                  <option value="regularAdmin">regularAdmin</option>
                </Select>
              </FormControl>
            </Flex>
            <Flex mb={["30px", "30px", "0px"]} justify={"flex-end"}>
              <Button
                bg="#5E6CE7"
                color={"#fff"}
                borderRadius="25px"
                px="30px"
                mt={["10px", "10px", "10px", "0px"]}
                isLoading={loading}
                // isDisabled={ !name || !email || !adminType}
                _hover={{
                  bg: "#5E6CE7",
                }}
                onClick={handleSubmit}
              >
                Edit Admin
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
