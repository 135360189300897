import React from "react";
import FullPageLoader from "../components/fullPageLoader";
import { Box, Flex, Text } from "@chakra-ui/react";
import PageHeader from "../components/PageHeader";
import CustomTable from "../components/table/CustomTable";
import { Pagination } from "../components/Pagination";
import { useState } from "react";
import AdminTableBody from "../components/table/AdminTableBody";
import { useEffect } from "react";
import { getAdmin } from "../services/admin";
import { AdminTableHead } from "../table.constants";
import AdminFilterSection from "../components/AdminFilterSection";

export const Admin = () => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [admin, setAdmin] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage] = useState(20);
  const [filteredAdmin, setFilteredAdmin] = useState([]);

  useEffect(() => {
    getAdmin(setAdmin, setFilteredAdmin, setLoading);
  }, [refresh]);

  const handleFilter = (searchParam) => {
    const updatedFilteredAdmin = filteredAdmin.filter((data) =>
      data.name.toLowerCase().includes(searchParam.toLowerCase())
    );
    setFilteredAdmin(updatedFilteredAdmin);
  };
  const handleSearch = (event) => {
    const searchParam = event.target.value;
    if (searchParam === "")
      setFilteredAdmin(admin.filter((data) => !data.isDelete));
    else handleFilter(searchParam);
  };

  // const indexOfLastRecord = currentPage * recordPerPage;
  ///const indexOfFirstRecord = indexOfLastRecord - recordPerPage;

  //const users = "foundUsers".length > 0 ? "foundUsers" : "members";

  //const currentRecord = admin?.slice(indexOfFirstRecord, indexOfLastRecord);
  const total =
    recordPerPage * currentPage + filteredAdmin.length - recordPerPage;

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <PageHeader title="Admin" />
      <AdminFilterSection
        setRefresh={setRefresh}
        data={filteredAdmin}
        handleSearch={handleSearch}
      />
      <Box bg={"#fff"} borderRadius="10px" mt="20px" width={"100%"}>
        <CustomTable tableHeadData={AdminTableHead}>
          <AdminTableBody data={filteredAdmin} setRefresh={setRefresh} />
        </CustomTable>
      </Box>
      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {total} of {filteredAdmin.length} Users
        </Text>
        <Pagination
          totalData={filteredAdmin.length ?? 0}
          dataPerPage={recordPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};
