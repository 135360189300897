import React from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";


export const FormInputs = ({ label, type, name, value, handleChange }) => {
  const formColor = "#616161";
  const fontSize = "18px";
  return (
    <FormControl>
      <FormLabel color={formColor} fontSize={fontSize}>
        {label}
      </FormLabel>
      <Input type={type} h="54px" name={name} value={value} onChange={handleChange} />
    </FormControl>
  );
};
