import { Avatar, Flex, Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import CustomDrawer from "../Drawer";
import { formatToNaira } from "../../utils/numberFormat";
import LoanRepaymentsDrawerContent from "../drawerChildren/LoanRepaymentsDrawerContent";

const LoanRepaymentsTableBody = ({ data }) => {
  console.log(data, "streeze");
  return (
    <Tbody>
      {data?.map((datum) => (
        <Tr key={datum?._id} fontSize={"16px"} width="100%">
          <Tooltip
            label={datum?.borrowerId?.fullName || datum?.userId?.[0]?.fullName || datum?.userId?.fullName}
          >
            <Td
              isTruncated
              maxWidth="150px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Flex align={"center"}>
                <Avatar
                  mr="10px"
                  size={"sm"}
                  src={datum?.borrowerId?.fullName || datum?.userId?.[0]?.image || datum?.userId?.fullName}
                  alt={
                    datum?.borrowerId?.fullName || datum?.userId?.[0]?.fullName || datum?.userId?.fullName
                  }
                  name={
                    datum?.borrowerId?.fullName || datum?.userId?.[0]?.fullName || datum?.userId?.fullName
                  }
                />
                <Text isTruncated maxWidth="150px !important">
                  {datum?.borrowerId?.fullName || datum?.userId?.[0]?.fullName || datum?.userId?.fullName}
                  
                </Text>
              </Flex>
            </Td>
          </Tooltip>
          <Tooltip label={formatToNaira(datum?.amount)}>
            <Td
              isTruncated
              width="120px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.amount && formatToNaira(datum?.amount)}
            </Td>
          </Tooltip>
          <Tooltip
            label={
              datum?.loanDisbursed
                ? dayjs(datum?.disbursedDate).format("MMM, DD YYYY")
                : "Not disbursed yet"
            }
          >
            <Td
              isTruncated
              maxWidth="150px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.loanDisbursed
                ? dayjs(datum?.disbursedDate).format("MMM, DD YYYY")
                : "Not disbursed yet"}
            </Td>
          </Tooltip>
          <Tooltip label={dayjs(datum?.dueDate).format("DD MMM YYYY")}>
            <Td
              isTruncated
              maxWidth="150px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.loanDisbursed &&
                dayjs(datum?.dueDate).format("DD MMM YYYY")}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.lenderId?.fullName}>
            <Td
              isTruncated
              maxWidth="150px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.lenderId?.fullName || "N/A"}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.interestRate + "%"}>
            <Td
              isTruncated
              maxWidth="150px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.interestRate ? `${datum?.interestRate}%` : "N/A"}
            </Td>
          </Tooltip>
          <Tooltip label={formatToNaira(datum?.totalAmountDue)}>
            <Td
              isTruncated
              maxWidth="150px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.totalAmountDue
                ? formatToNaira(datum?.totalAmountDue)
                : "Not Available"}
            </Td>
          </Tooltip>
          <Tooltip label={formatToNaira(datum?.amountRepaid)}>
            <Td
              isTruncated
              maxWidth="150px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {formatToNaira(datum?.amountRepaid) || formatToNaira("0")}
            </Td>
          </Tooltip>

          <Tooltip label={datum?.repaymentStatus}>
            <Td
              color="#C9A721"
              isTruncated
              maxWidth="150px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
              textAlign="center"
            >
              <Text
                textTransform="capitalize"
                bg={
                  // datum?.directDebitDetails?.chargeStatus==="success"
                  // ? "green.300"
                  // :
                  datum?.repaymentStatus?.toLowerCase() === "active"
                    ? "blue.300"
                    : datum?.repaymentStatus?.toLowerCase() === "completed"
                    ? "green.300"
                    : "red"
                }
                color={
                  datum?.repaymentStatus?.toLowerCase() === "active" ||
                  datum?.repaymentStatus?.toLowerCase()==="completed"
                    ? "#fff"
                    : "#fff"
                }
                p="2px 10px"
                borderRadius={"10px"}
              >
                {
                // datum?.directDebitDetails?.chargeStatus==="success"?"completed":
                datum?.repaymentStatus || "pending"}
              </Text>
            </Td>
          </Tooltip>
          <Td isTruncated maxWidth="100px !important" px="10px !important">
            <Flex justify={"space-between"}>
              <CustomDrawer
                icon={AiOutlineEye}
                header={"Loan Report Details"}
                iconColor={"#5E6CE7"}
                data={data}
              >
                <LoanRepaymentsDrawerContent datum={datum} />
              </CustomDrawer>
            </Flex>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default LoanRepaymentsTableBody;
