import { TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import CustomTable from "../table/CustomTable";
import LoanReportsTableBody from "../table/LoanReportsTableBody";
import TabHeadings from "./TabHeadings";

const LoanReportsTab = ({
  tabHeadings,
  approvedData,
  disbursalHeadData,
  tableHeadData,
  allStatus,
  setTabName,
  setFoundLoans,
  setRefresh,
}) => {
  console.log(approvedData,"mmmmmm")
  return (
    <Tabs width="100%">
      <TabHeadings
        tabHeadings={tabHeadings}
        setTabName={setTabName}
        setFoundUsers={setFoundLoans}
        allStatus={allStatus}
      />

      <TabPanels width="100% !important">
        <TabPanel width="100% !important" p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <LoanReportsTableBody data={allStatus} setRefresh={setRefresh} />
          </CustomTable>
        </TabPanel>
        <TabPanel p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <LoanReportsTableBody data={allStatus} setRefresh={setRefresh} />
          </CustomTable>
        </TabPanel>
        <TabPanel p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <LoanReportsTableBody data={allStatus} setRefresh={setRefresh} />
          </CustomTable>
        </TabPanel>

        <TabPanel p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <LoanReportsTableBody data={allStatus} setRefresh={setRefresh} />
          </CustomTable>
        </TabPanel>4
        
        <TabPanel p="0 !important">
          <CustomTable tableHeadData={disbursalHeadData}>
            <LoanReportsTableBody data={approvedData} setRefresh={setRefresh} />
          </CustomTable>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default LoanReportsTab;
