import React from "react";
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { AddAdminDrawerContent } from "./drawerChildren/AddAdminDrawerContent";
import { AiOutlineSearch } from "react-icons/ai";

const AdminFilterSection = ({
  handleSearch,
  data,
  setRefresh,
}) => {
  //disable download
  //const disableBtn = state?.userInfo?.adminType === "superAdmin";

  //csv download
  const headers = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "email",
      label: "Email Address",
    },
    {
      key: "adminType",
      label: "Role",
    },
  ];

  data?.map((data) => {
    return {
      name: data?.name || "--",
      email: data?.email || "--",
      adminType: data?.adminType || "--",
    };
  });

  return (
    <Flex
      justify={"space-between"}
      align="center"
      bg={"#fff"}
      borderRadius={"10px"}
      p="20px"
      direction={["column", "column", "row", "row"]}
    >
      <InputGroup width={["100%", "100%", "270px", "400px"]}>
        <InputLeftElement
          pointerEvents="none"
          children={<AiOutlineSearch color="lightblue" size={24} />}
        />
        <Input
          bg="#ECECEE"
          color="#140236"
          borderRadius={"35px"}
          type="text"
          placeholder="Search..."
          onChange={handleSearch}
        />
      </InputGroup>{" "}
      <AddAdminDrawerContent setRefresh={setRefresh} />
      <Button
        bg="#5E6CE7"
        color={"#fff"}
        borderRadius="25px"
        width={["100%", "100%", "200px", "200px"]}
        mt={["10px", "10px", "0px", "0px"]}
        //isDisabled={!disableBtn}
        _hover={{
          bg: "#5E6CE7",
        }}
      >
        <CSVLink headers={headers} data={data}>
          Download CSV File
        </CSVLink>
      </Button>
    </Flex>
  );
};

export default AdminFilterSection;
