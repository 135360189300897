import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const loginUser = async (payload, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.post(AUTH_ROUTES.LOGIN, payload);
    setLoading(false);
    console.log(data, "Logged In")
    const token = data?.token; 
    sessionStorage.setItem("#WUEPP#", token);
    window.location.reload();
    return data;
  } catch (e) {
    setLoading(false);
     if (e.response) {
       errorNotifier(
         e.response?.data?.message || e.response?.data?.data?.message
       );
     } else {
       errorNotifier("Network Error, Please check your internet connection");
     }
   
  }
};

export const sendOtp = async (payload, setLoading, setNavigateTo) => {
  try {
    const {
      data: { data },
    } = await http.post(AUTH_ROUTES.SEND_OTP, payload);
    setLoading(false);
    successNotifier("Email sent successfully");
      setNavigateTo("verifyOtp");
    console.log(data, "forgot password");
  } catch (e) {
    console.log(e.response);
    setLoading(false);
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

export const verifyOtp = async (payload, setLoading, setNavigateTo) => {
  try {
    const {
      data: { data },
    } = await http.post(AUTH_ROUTES.VERIFY_OTP, payload);
    setLoading(false);
    successNotifier("OTP verified successfully");
      setNavigateTo("resetPassword");
    console.log(data, "verify");
  } catch (e) {
    console.log(e.response);
    setLoading(false);
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

export const resetPassword = async (payload, setLoading, navigate) => {
  try {
    const {
      data: { data },
    } = await http.post(AUTH_ROUTES.RESET_PASSWORD, payload);
    setLoading(false);
    successNotifier("Password changed successfully");
      navigate("/login");
    console.log(data, "reset");
  } catch (e) {
    console.log(e.response);
    setLoading(false);
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};
