import React, { useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { CustomBtn } from "../components/CustomBtn";
import { verifyRepayment } from "../services/loanRepayments";
import { useNavigate } from "react-router-dom";

const RepaymentVerification = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState()
    const location = useLocation()
    const data = location?.state
    console.log(data,"7")

    const handleVerify  = async (e) =>{
            e.preventDefault()
            setLoading(true)
            const payload = {
              reference:data?.data?.data?.reference
            };
            console.log("PAYLOAD", payload);
            await verifyRepayment(payload, setLoading, navigate);
    }

  return (
    <Box>
      <Text fontSize={"50px"} fontWeight={"bold"} textAlign={"center"} pb={"50px"}>
        Kindly verify loan repayment
      </Text>
      <Flex justify={"center"}>
        <CustomBtn loading={loading} text={"verify repayment"} bg={"green.300"} handleClick={handleVerify}/>
      </Flex>
    </Box>
  );
};
 
export default RepaymentVerification;
