import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";
import axiosInstance from "./formDataConfig";

export const updateProfile = async (payload, setLoading, setRefresh) => {
  try {
    const {
      data: { data },
    } = await http.put(AUTH_ROUTES.UPDATE_PROFILE(payload?.userId), payload);
    setLoading(false);
    console.log(data, "PROFILE DATA");
    successNotifier("Profile successfully updated");
    setRefresh((prev) => !prev);
  } catch (e) {
    setLoading(false);
    console.log("Erroeeerrrr", e);
    errorNotifier(
      e?.response?.data?.message || e?.response?.data?.data?.message
    );
  }
};

export const updatePassword = async (payload, setLoading, setRefresh) => {
  try {
    const {
      data: { data },
    } = await http.put(AUTH_ROUTES.UPDATE_PASSWORD, payload);
    setLoading(false);
    console.log(data, "PROFILE DATA");
    successNotifier("Password successfully updated");
    setRefresh((prev) => !prev);
  } catch (e) {
    setLoading(false);
    errorNotifier(
      e?.response?.data?.message || e.response?.data?.data?.message
    );
  }
};

export const updateProfileImage = async (payload, setLoading, setRefresh) => {
  try {
    await axiosInstance.post(AUTH_ROUTES.UPDATE_IMAGE, payload);
    setLoading(false);
    successNotifier("Profile image successfully updated");
    setRefresh((prev) => !prev);
  } catch (e) {
    setLoading(false);
    console.log(e?.response, "UPLOAD ERROR");
    errorNotifier(
      e?.response?.data?.message || e.response?.data?.data?.message
    );
  }
};
