import { Box, Flex, Heading, Text, Image, Button } from "@chakra-ui/react";
import Bg from "../../assets/loginBg2.png";
import loginLogo from "../../assets/loginLogo.png";
import { FormInputs } from "../FormInput";
import { sendOtp } from "../../services/login";

const SendOtp = ({ setNavigateTo, setLoading, email, setEmail, loading }) => {
  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      type: "email",
      userDetail: email,
      // email: email,
      length: 4,
    };
    sendOtp(payload, setLoading, setNavigateTo);
  };
  return (
    <Flex
      w={"100%"}
      background={"#fff"}
      bgImage={Bg}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      align="center"
      width="full"
      height="100vh"
      justify="center"
      gap="169px"
      pl={["0px", "0px", "200px"]}
      pr={["0px", "0px", "56px"]}
      py="92px"
      direction={["column", "column", "row"]}
    >
      <Flex
        pt={["400px", "400px", "0px"]}
        w={["200px", "250px", "350px", "400px"]}
      >
        <Image src={loginLogo} alt="" />
      </Flex>
      <Box
        w={["100%", "100%", "595px"]}
        px="48px"
        pt="78px"
        pb="54px"
        bg="#fff"
        borderRadius="5px"
        align="center"
      >
        <Flex direction="column" align="start">
          <Heading color="#0D1139" fontSize={["32px", "32px", "40px"]}>
            Forgot Password?
          </Heading>
          <Text
            pt="11px"
            pb="72px"
            color="#1A0031"
            fontSize="20px"
            fontWeight="semibold"
          >
            Enter your email to recieve OTP
          </Text>
        </Flex>

        <Flex direction="column" gap="30px">
          <FormInputs
            type={"email"}
            name={"email"}
            label={"Email Address"}
            value={email}
            handleChange={(e) => setEmail(e.target.value)}
          />
        </Flex>

        <Button
          colorScheme="blue"
          borderRadius="5px"
          justifyContent="center"
          mt="49px"
          py="15px"
          w={["100%", "100%", "250px"]}
          isLoading={loading}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </Box>
    </Flex>
  );
};

export default SendOtp;
