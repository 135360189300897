import React from "react";
import { Box, Text } from "@chakra-ui/react";
import MessageCheckbox from "./MessageCheckbox";
import MessageTextareas from "./MessageTextareas";

function MessageBox({
  recipient,
  setTypes,
  types,
  onClose,
  selectedItems,
}) {
  return (
    <Box
      w="100%"
      h={["508px", "508px", "558px"]}
      bg="#fff"
      py="36px"
      px="31px"
      borderRadius="2px"
    >
      <Text fontSize="14px" color="#264653" fontWeight="bold" pb="15px">
        Sending Message Via:
      </Text>
      <MessageCheckbox setTypes={setTypes} />
      <Text fontSize="14px" color="#264653" fontWeight="bold" pb="15px">
        message
      </Text>
      <MessageTextareas
        recipient={recipient}
        selectedItems={selectedItems}
        types={types}
        onClose={onClose}
      />
    </Box>
  );
}

export default MessageBox;
