import React from "react";
import { Box, Center, Circle, Flex, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { IoMdNotificationsOutline } from "react-icons/io";
import bg from "../assets/cardBg.svg";
import NotificationContent from "./notifications/NotificationContent";
import CustomDrawer from "./Drawer";

const GreetingsCard = () => {
 
  return (
    <Flex
      width={"100%"}
      height="100%"
      bg={"#0D1139"}
      p="20px"
      borderRadius={"10px"}
      alignItems="flex-start"
      backgroundImage={bg}
      opacity={0.9}
      backgroundSize={"cover"}
      backgroundRepeat="no-repeat"
    >
      <Flex
        flexDirection={"column"}
        justifyContent="space-between"
        width={"100%"}
        height={"100%"}
      >
        <Box width={"100%"}>
          <Text color={"#D4CECE"}>Welcome Back</Text>
          <Text color={"#fff"} fontSize="28px" fontWeight={"bold"}>
            WhoUepp Admin
          </Text>
        </Box>
        <Text mt="20px" color={"#D4CECE"}>
          {dayjs().format("DD/MM/YYYY")}
        </Text>
      </Flex>
      <Circle width="36px" height="36px" bg={"#fff"} pl={"10px"}>
        <CustomDrawer
          icon={IoMdNotificationsOutline}
          iconSize={24}
        >
          <NotificationContent />
        </CustomDrawer>
      </Circle>
    </Flex>
  );
};

export default GreetingsCard;
