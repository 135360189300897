import { Tab, TabList } from "@chakra-ui/react";
import React from "react";

const TabHeadings = ({ tabHeadings, setTabName, setFoundUsers }) => {
  return (
    <TabList width={"100%"} >
      {tabHeadings.map((tab) => (
        <Tab
          pt="5 !important"
          pb="1 !important"
          _selected={{
            borderBottom: "8px solid #5E6CE7",
            color: "#030007",
            fontWeight: "bold",
          }}
          onClick={() => {
            setTabName(tab);
          }}
        >
          {tab}
        </Tab>
      ))}
    </TabList>
  );
};

export default TabHeadings;
