import { Flex, Tbody, Td, Tooltip, Tr } from "@chakra-ui/react";
import React from "react";
import { EditAdminDrawer } from "../drawerChildren/EditAdminDrawer";
import { DeleteAdminDrawer } from "../drawerChildren/DeleteAdminDrawer";

const AdminTableBody = ({ data, setRefresh }) => {
  return (
    <Tbody>
      {data.map((datum, key) => (
        <Tr key={key} fontSize={"16px"} width="100%">
          <Tooltip label={datum?.name}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
              p={"20px 10px"}
            >
              {datum?.name || "N/A"}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.email}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
              p={"20px 10px"}
            >
              {datum?.email || "N/A"}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.adminType}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
              p={"20px 10px"}
            >
              {datum?.adminType || "N/A"}
            </Td>
          </Tooltip>
          <Td isTruncated maxWidth="170px !important" p={"20px 10px"}>
            <Flex justify={"start"} gap={"20px"}>
              <Tooltip label="Edit" fontSize="md">
                <span>
                  <EditAdminDrawer setRefresh={setRefresh} data={datum} />
                </span>
              </Tooltip>
              <Tooltip label="Delete" fontSize="md">
                <span>
                  <DeleteAdminDrawer setRefresh={setRefresh} data={datum} />
                </span>
              </Tooltip>
            </Flex>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default AdminTableBody;
