export const dashboardTableHead = [
  "User Name",
  "Email Address",
  "Phone Number",
  "Registration Date",
  "Account Type",
  "Status",
  "Action",
];
export const AdminTableHead = ["Name", "Email Address", "Role", "Action"];

export const lendersTableHead = [
  "ID",
  "User Name",
  "Email Address",
  "Phone Number",
  "Registration Date",
  "Total Amount Disbursed",
  "Total Repaid Loan",
  "Status",
  "Action",
];

export const borrowersTableHead = [
  "ID",
  "User Name",
  "Email Address",
  "Phone Number",
  "Registration Date",
  "Total Amount Requested",
  "Total Repaid Loan",
  "Status",
  "Action",
];

export const loanReportsTableHead = [
  "loan Id",
  "Borrowers Name",
  "Loan Requested",
  "Date of Request",
  "Loaning Category",
  "Peered Lender",
  "Verification Status",
  "Lender Transfer",
  "Loan Disbursed Date",
  "Debit Consent",
  // "Loan Due Date",
  "Lender Status",
  "Transaction Status",
  "Action",
];

export const DisbursalTableHead = [
  "Borrowers Name",
  "Loan Amount Requested",
  // "Amount",
  "Date of Request",
  "Loaning Category",
  "Peered Lender",
  "Lender Counter Amount",
  "Loan Disbursed Date",
  "Loan Due Date",
  "Status",
  "Action",
];

export const loanRepaymentsTableHead = [
  "Borrowers Name",
  "Loan Amount Received",
  "Disbursed Date",
  "Due Date",
  "Lender Receiving Payment",
  "Loan Interest",
  "Total Repayment Amount",
  "Amount Returned out of Loan",
  "Status",
  "Action",
];

export const lendingTabTitles = ["All Status", "Active", "Pending"];

export const borrowersTabTitles = [
  "All Status",
  "Active",
  "Unverified",
  "Suspended",
];
export const loanReportsTabTitles = [
  "All Status",
  "Pending",
  "Approved",
  "Expired",
  // "Disbursal"
];
export const loanRepaymentsTabTitles = [
  "All Status",
  "Completed",
  "Pending",
  "Due",
];
