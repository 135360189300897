export const Pagination = ({
  dataPerPage,
  totalData,
  // paginate,
  currentPage,
  setCurrentPage,
}) => {
  const pageNumbers = [];
  const nPage = Math.ceil(totalData / dataPerPage);

  for (let i = 1; i <= Math.ceil(totalData / dataPerPage); i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    if (currentPage !== nPage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <nav>
      <ul style={{ display: "flex" }}>
        <li
          style={{
            listStyleType: "none",
            cursor: "pointer",
            marginRight: "10px",
          }}
          cursor="pointer"
          onClick={prevPage}
        >
          Prev
        </li>
        {pageNumbers?.map((number) => (
          <li
            key={number}
            style={{
              listStyle: "none",
              marginRight: "10px",
              border: "1px solid #0D1139",
              width: "40px",
              textAlign: "center",
              borderRadius: "5px",
              cursor: "pointer",
              background: currentPage === number ? "#0D1139" : "#fff",
              color: currentPage === number ? "#fff" : "#0D1139",
            }}
            onClick={() => paginate(number)}
          >
            {number}
          </li>
        ))}
        <li
          style={{ listStyleType: "none", cursor: "pointer" }}
          onClick={nextPage}
        >
          Next
        </li>
      </ul>
    </nav>
  );
};
