import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import FilterSection from "../components/FilterSection";
import FullPageLoader from "../components/fullPageLoader";
import LoanRepaymentsTab from "../components/tabs/LoanRepaymentsTab";
import {
  getCompletedLoanRepayments,
  getDueLoanRepayments,
  getPendingLoanRepayments,
  getSingleLoan,
  searchAllLoanRepayments,
  searchLoanRepaymentsByDates,
} from "../services/loanRepayments";
import { getApprovedLoanReports, getRepayment } from "../services/loanReports";
import {
  loanRepaymentsTableHead,
  loanRepaymentsTabTitles,
} from "../table.constants";
import { Pagination } from "../components/Pagination";
import PageHeader from "../components/PageHeader";

const LoanRepayments = () => {
  const [pendingRepayments, setPendingRepayments] = useState([]);

  const [repayment, setRepayment] = useState([])
  const [allLoanRepayments, setAllLoanRepayments] = useState([]);
  const [completedRepayments, setCompletedRepayments] = useState([]);
  const [dueRepayments, setDueRepayments] = useState([]);
  const [foundLoans, setFoundLoans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tabName, setTabName] = useState("All Status");

  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage] = useState(20);
  console.log(allLoanRepayments,"ffjjf")

  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  

  console.log(repayment,"WASIU")
  const oweing = repayment.filter((data)=>data?.paymentConfirmation==="confirmed")
  console.log(oweing,"MALK")

  const dataByTabName =
    tabName === "Pending"
      ? pendingRepayments
      : tabName === "Completed"
      ? completedRepayments
      : tabName === "Due"
      ? dueRepayments
      : allLoanRepayments;
  const users = foundLoans.length > 0 ? foundLoans : dataByTabName;

  const currentRecord = users?.slice(indexOfFirstRecord, indexOfLastRecord);
  const total =
    recordPerPage * currentPage + currentRecord.length - recordPerPage;
 
  useEffect(() => {
   
    getApprovedLoanReports(setAllLoanRepayments, setLoading);
   
    getRepayment(setRepayment,setLoading)
    getPendingLoanRepayments(setPendingRepayments, setLoading);
    getCompletedLoanRepayments(setCompletedRepayments, setLoading);
    getDueLoanRepayments(setDueRepayments, setLoading);
  }, []);
  console.log(dueRepayments,"days");
     console.log(allLoanRepayments, "loan repayment");


  const handleSearch = (e) => {
    searchAllLoanRepayments(setFoundLoans, setLoading, e.target.value);
  };

  const handleSearchByDate = (e) => {
    const status =
      tabName === "Pending"
        ? "pending"
        : tabName === "Completed"
        ? "completed"
        : tabName === "Due"
        ? "due"
        : undefined;

    searchLoanRepaymentsByDates(
      setFoundLoans,
      setLoading,
      startDate,
      e.target.value,
      status && status,
      status
    );
  };

  // console.log("Loan reports", foundLoans);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <PageHeader title="Loan Repayments" />
      <Box>
        <FilterSection
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleSearch={handleSearch}
          handleSearchByDate={handleSearchByDate}
        />
      </Box>
      <Box bg={"#fff"} borderRadius="10px" mt="20px" width={"100%"}>
        <LoanRepaymentsTab
          allLoanRepayments={currentRecord}
          // allLoanRepayments={repayment}
          tabHeadings={loanRepaymentsTabTitles}
          tableHeadData={loanRepaymentsTableHead}
          setTabName={setTabName}
          setFoundLoans={setFoundLoans}
        />
      </Box>
      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {total} of {users.length} Users
        </Text>
        <Pagination
          totalData={users.length ?? 0}
          dataPerPage={recordPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};

export default LoanRepayments;
