import { createContext, useEffect, useState } from "react";
import { getBorrowersAndLenders } from "../services/borrowersAndLenders";
import { getUserProfile } from "./getUserProfile";
import { useGetState } from "./useGetState";

export const UserProfileContext = createContext({});

export const UserProfileContextProvider = ({ children }) => {
  const [state, setState] = useState({ signin: true, reload: false });
  // console.log("THE STATE55000,", state);
  // const token = sessionStorage.getItem("#WUEPP#");
  const { state: globalState } = useGetState() || {};
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserProfile(setState, setLoading);
    getBorrowersAndLenders(setUsersData, setLoading);
  }, [globalState, state?.reload]);

  const dispatch = (incoming) => {
    setState((prev) => ({ ...prev, ...incoming }));
  };

  return (
    <UserProfileContext.Provider
      value={{ dispatch, state, loading, usersData }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
