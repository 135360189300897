import { errorNotifier, successNotifier } from "../index";
import http, { AUTH_ROUTES } from "./api";

export const postMessages = async (payload, setLoading, setMsg) => {
  try {
    await http.post(AUTH_ROUTES.NOTIFICATIONS, payload);
    setLoading(false);
    successNotifier("Message sent successfully");
    setMsg("");
  } catch (e) {
    setLoading(false);
    errorNotifier(
      e?.response?.data?.message || e?.response?.data?.data?.message
    );
  }
};

export const updateAdmin = async (
  payload,
  setLoading,
  setRefresh,
  adminId,
  onClose
) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_ADMIN(adminId), payload);
    setLoading(false);
    successNotifier("Admin Updated added");
    setRefresh((prev) => !prev);
    onClose();
  } catch (e) {
    console.log(e, "error updating admin");
    setLoading(false);
    errorNotifier(e?.response?.data?.message);
  }
};
