import React, { useState } from "react";
import { Box, Checkbox, Flex, TabPanel, TabPanels } from "@chakra-ui/react";
import { Tabs, TabList, Tab } from "@chakra-ui/react";
import MessageLenderInputs from "./MessageLenderInputs";
import { useEffect } from "react";
import {
  getBorrowers,
  getBorrowersAndLenders,
} from "../../services/borrowersAndLenders";

function MessageUserForm({
  usersData,
  setRecipient,
  selectedItems,
  setSelectedItems,
  setLoading,
}) {
  //const { allLenders, allBorrowers } = usersData;
  const [check, setCheck] = useState(false);
  const [userType, setUserType] = useState("lenders");
  const [checkedItems, setCheckedItems] = useState({});
  const [borrowerData, setBorrowerData] = useState([]);
  const [lenderData, setLenderData] = useState([]);

  console.log(usersData, "dataa", borrowerData);
  const borrowersOrLenders = userType === "lenders" ? lenderData : borrowerData;
  // const handleCheckboxChange = (users, value) => {
  //   if (value === "All") {
  //     const newCheckedItems = {};
  //     users.forEach((item) => {
  //       newCheckedItems[item._id] = !checkedItems[value];
  //     });
  //     setCheckedItems(newCheckedItems);
  //     if (!checkedItems[value]) {
  //       setSelectedItems(
  //         users.filter((item) => item._id !== value).map((item) => item._id)
  //       );
  //     } else {
  //       setSelectedItems([]);
  //     }
  //   } else {
  //     setCheckedItems({
  //       ...checkedItems,
  //       [value]: !checkedItems[value],
  //     });
  //     setSelectedItems((prevSelectedItems) => {
  //       if (checkedItems[value]) {
  //         return prevSelectedItems.filter((item) => item !== value);
  //       } else {
  //         return [...prevSelectedItems, value];
  //       }
  //     });
  //   }
  // };
const allIds = borrowersOrLenders.map((item) => item?._id);

useEffect(() => {
  // Check if all individual checkboxes are selected
  const allChecked = allIds.every((id) => checkedItems[id]);
  setCheckedItems((prevCheckedItems) => ({
    ...prevCheckedItems,
    All: allChecked,
  }));
}, [checkedItems, allIds]);

const handleCheckboxChange = (value) => {
  if (value === "All") {
    const newCheckedItems = { ...checkedItems };
    const allChecked = !newCheckedItems.All;
    allIds.forEach((id) => {
      newCheckedItems[id] = allChecked;
    });
    setCheckedItems(newCheckedItems);
    setSelectedItems(allChecked ? allIds : []);
    setRecipient(allChecked ? borrowersOrLenders : []);
  } else {
    const newCheckedItems = { ...checkedItems };
    newCheckedItems[value] = !newCheckedItems[value];
    setCheckedItems(newCheckedItems);

    if (!newCheckedItems[value]) {
      setCheckedItems((prevCheckedItems) => ({
        ...prevCheckedItems,
        All: false,
      }));
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== value)
      );
      setRecipient((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item?._id !== value)
      );
    } else {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, value]);
      setRecipient((prevSelectedItems) => [
        ...prevSelectedItems,
        usersData.find((item) => item?._id === value),
      ]);
    }
  }
};

  useEffect(() => {
    getBorrowersAndLenders(setLenderData, setLoading);
    getBorrowers(setBorrowerData, setLoading);
  }, [setLoading]);
  return (
    <Box
      className="table-container"
      h="560px"
      overflowY={"scroll"}
      w="100%"
      bg="#fff"
      borderRadius="2px"
      p="20px"
    >
      <Flex h="100%" justify="space-between" align={"flex-start"} pb="41px">
        <Box w="70%">
          <Tabs>
            <TabList color="#11131C">
              <Tab
                fontSize="14px"
                _selected={{
                  borderBottom: "3px solid #5E6CE7",
                  color: "#5E6CE7",
                  fontWeight: "semibold",
                }}
                onClick={() => setUserType("lenders")}
              >
                Lenders
              </Tab>
              <Tab
                fontSize="14px"
                _selected={{
                  borderBottom: "3px solid #5E6CE7",
                  color: "#5E6CE7",
                  fontWeight: "semibold",
                }}
                onClick={() => setUserType("borrowers")}
              >
                Borrowers
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px="0">
                <MessageLenderInputs
                  data={lenderData}
                  setRecipient={setRecipient}
                  setCheck={setCheck}
                  checkedItems={checkedItems}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </TabPanel>
              <TabPanel px="0">
                <MessageLenderInputs
                  data={borrowerData}
                  setRecipient={setRecipient}
                  setCheck={setCheck}
                  checkedItems={checkedItems}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box width="29%" mt="20px">
          <Checkbox
            size="md"
            colorScheme={"green"}
            value={"All"}
            isChecked={checkedItems.All || false}
            onChange={() => handleCheckboxChange("All")}
            //isChecked={check}
            // onChange={(e) => {
            //   setCheck(!check);
            //   handleCheckboxChange(borrowersOrLenders, "All");
            // }}
          >
            Select All
          </Checkbox>
        </Box>
      </Flex>
    </Box>
  );
}

export default MessageUserForm;
