import React from "react";
import { Button, Flex } from "@chakra-ui/react";
import DateField from "./DateField";
import SearchField from "./SearchField";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import { useGetState } from "../contexts/useGetState";

const FilterSection = ({
  handleSearch,
  name,
  endDate,
  startDate,
  setName,
  setEndDate,
  setStartDate,
  handleSearchByDate,
  pending,
  activeLenders,
  suspended,
  unverified,
  usersData,
  tabName,
  page,
  approvedLoanReports,
  expiredLoanReports,
}) => {
  const { state } = useGetState();
  //disable download
  const disableBtn = state?.userInfo?.adminType === "superAdmin";

  //csv download
  const headers = [
    {
      key: "name",
      label: "User Name",
    },
    {
      key: "email",
      label: "Email Address",
    },
    {
      key: "phone",
      label: "Phone Number",
    },
    {
      key: "date",
      label: "Registration Date",
    },
    {
      key: "amount",
      label: "Total Amount Disbursed",
    },
    {
      key: "repaid",
      label: "Total Repaid Loan",
    },
    {
      key: "status",
      label: "Status",
    },
  ];

  const headers2 = [
    {
      key: "BorrowersName",
      label: "Borrowers Name",
    },
    {
      key: "loanAmount",
      label: "Loan Amount Requested",
    },
    {
      key: "date",
      label: "Date of Request",
    },
    {
      key: "Loaning",
      label: "Loaning Category",
    },
    {
      key: "peered",
      label: "Peered Lender",
    },
    {
      key: "lender",
      label: "Lender Counter Amount",
    },
    {
      key: "loanDisbursed",
      label: "Loan Disbursed Date",
    },
    {
      key: "loanDue",
      label: "Loan Due Date",
    },
    {
      key: "status",
      label: "Status",
    },
  ];
  const currentTab =
    tabName === "All Status"
      ? usersData
      : tabName === "Active"
      ? activeLenders
      : tabName === "Pending"
      ? pending
      : tabName === "Suspended"
      ? suspended
      : tabName === "Approved"
      ? approvedLoanReports
      : tabName === "Expired"
      ? expiredLoanReports
      : unverified;

  // console.log("currentt", currentTab);
  const data =
    page === "userTable"
      ? currentTab?.map((data) => {
          return {
            name: data?.fullName || "--",
            email: data?.email || "--",
            phone: String(data?.phoneNumber) || "--",
            date: dayjs(data?.createdAt).format("MM/DD/YYYY") || "--",
            amount: "--",
            repaid: "--",
            status: data?.status || "--",
          };
        })
      : currentTab?.map((data) => {
          return {
            BorrowersName: data?.userId?.fullName || "--",
            loanAmount: data?.amount || "--",
            date: dayjs(data?.createdAt).format("MM/DD/YYYY") || "--",
            Loaning: data?.loanCategory || "--",
            peered: "--",
            lender: "--",
            loanDisbursed: data?.loanDisbursed
              ? "Disbursed"
              : "Not yet disbursed" || "--",
            loanDue: data?.totalAmountDue || "--",
            status: data?.status || "--",
          };
        });

  return (
    <Flex
      justify={"space-between"}
      align="center"
      bg={"#fff"}
      borderRadius={"10px"}
      p="20px"
      direction={["column", "column", "column", "row"]}
    >
      <SearchField handleSearch={handleSearch} />
      <Flex
        justify={"space-between"}
        align="center"
        width={["100%", "100%", "100%", "40%"]}
        mt={["10px", "10px", "10px", "0px"]}
        flexWrap={"wrap"}
        gap={["10px", "10px", "20px", "0px"]}
      >
        <DateField
          width={["100%", "100%", "100%", "49%"]}
          text={"From"}
          date={startDate}
          setDate={setStartDate}
        />
        <DateField
          width={["100%", "100%", "100%", "44%"]}
          text={"To"}
          date={endDate}
          setDate={setEndDate}
          handleSearch={handleSearchByDate}
        />
      </Flex>
      <CSVLink
        headers={page === "userTable" ? headers : headers2}
        data={data || "data"}
      >
        <Button
          bg="#5E6CE7"
          color={"#fff"}
          borderRadius="25px"
          px="30px"
          mt={["10px", "10px", "10px", "0px"]}
          isDisabled={!disableBtn}
          _hover={{
            bg: "#5E6CE7",
          }}
        >
          Download CSV File
        </Button>
      </CSVLink>
    </Flex>
  );
};

export default FilterSection;
