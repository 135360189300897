import { Heading } from "@chakra-ui/react";
import React from "react";

const PageHeader = ({ title }) => {
  return (
    <Heading
      as="h2"
      mb="20px"
      textTransform="capitalize"
      color="grey"
      fontWeight={"bold"}
    >
      {title}
    </Heading>
  );
};

export default PageHeader;
