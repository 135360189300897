import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FilterSection from "../components/FilterSection";
import FullPageLoader from "../components/fullPageLoader";
import { Pagination } from "../components/Pagination";
import CustomTab from "../components/tabs/CustomTab";
import {
  getActiveLenders,
  getBorrowersAndLenders,
  getPendingLenders,
  searchUsers,
  searchUsersByDates,
  updateLenderStatus,
} from "../services/borrowersAndLenders";
import { lendersTableHead, lendingTabTitles } from "../table.constants";
import PageHeader from "../components/PageHeader";

const Lenders = () => {
  // const { usersData } = useGetState();
  const [usersData, setUsersData] = useState([]);
  const [pendingLenders, setPendingLenders] = useState([]);
  const [activeLenders, setActiveLenders] = useState([]);
  const [foundUsers, setFoundUsers] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [tabName, setTabName] = useState("All Status");
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage] = useState(20);

  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;

  const dataByTabName =
    tabName === "Active"
      ? activeLenders
      : tabName === "Pending"
      ? pendingLenders
      : usersData;
  const users = foundUsers.length > 0 ? foundUsers : dataByTabName || [];

  const handleTabChange = (tab) => {
    setTabName(tab);
    setFoundUsers([]);
    setRefresh(true);
  };

  const currentRecord = users?.slice(indexOfFirstRecord, indexOfLastRecord);
  const total =
    recordPerPage * currentPage + currentRecord?.length - recordPerPage;

  console.log(usersData, "userdata");
  const handleSearch = (e) => {
    searchUsers(
      setFoundUsers,
      setLoading,
      e.target.value,
      startDate,
      endDate,
      "lender",
      tabName
    );
  };

  const handleSearchByDate = (e) => {
    searchUsersByDates(
      setFoundUsers,
      setLoading,
      startDate,
      e.target.value,
      "lender",
      tabName
    );
  };

  useEffect(() => {
    getBorrowersAndLenders(setUsersData, setLoading);
    getPendingLenders(setPendingLenders, setLoading);
    getActiveLenders(setActiveLenders, setLoading);
  }, [refresh]);

  //status update
  const handleUpdate = async (id, status, onClose) => {
    setBtnLoading(true);
    await updateLenderStatus(id, status, setBtnLoading, setRefresh, onClose);
  };
  // console.log("Lenders Active", activeLenders);
  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <PageHeader title="Lenders" />
      <Box>
        <FilterSection
          handleSearch={handleSearch}
          handleSearchByDate={handleSearchByDate}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          usersData={users}
          pending={pendingLenders}
          activeLenders={activeLenders}
          tabName={tabName}
          page={"userTable"}
        />
      </Box>
      <Box bg={"#fff"} borderRadius="10px" mt="20px" width={"100%"}>
        <CustomTab
          setTabName={handleTabChange}
          setFoundUsers={setFoundUsers}
          data={currentRecord}
          tabHeadings={lendingTabTitles}
          tableHeadData={lendersTableHead}
          // pendingData={pendingLenders}
          // activeData={activeLenders}
          handleUpdate={handleUpdate}
          // setRefresh={setRefresh}
          btnLoading={btnLoading}
          // setLoading={setLoading}
        />
      </Box>
      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {total} of {users.length} Users
        </Text>
        <Pagination
          totalData={users.length ?? 0}
          dataPerPage={recordPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};

export default Lenders;
