import { Stack, Box, Flex } from "@chakra-ui/react";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserProfileContextProvider } from "../contexts/UserContext";
import AccountSettings from "../pages/AccountSettings";
import Borrowers from "../pages/Borrowers";
import Dashboard from "../pages/Dashboard";
import Lenders from "../pages/Lenders";
import LoanRepayments from "../pages/LoanRepayments";
import LoanReports from "../pages/LoanReports";
import Messages from "../pages/Messages";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import { useState } from "react";
import { Admin } from "../pages/Admin";
import RepaymentVerification from "../pages/RepaymentVerification";

function AuthenticatedRoutes() {
  const [showSidebar, setShowSidebar] = useState(true);
  const [tabWidth, setTabWidth] = useState("80px");

  return (
    <UserProfileContextProvider>
      <Suspense fallback={<p></p>}>
        <BrowserRouter>
          <Stack direction={"row"} position="relative" width="100%">
            {showSidebar && (
              <Box
                position={"fixed"}
                width={["", "", "", tabWidth, "260px"]}
                left="0"
                zIndex="1032"
              >
                <SideBar
                  showSidebar={showSidebar}
                  setShowSidebar={setShowSidebar}
                  setTabWidth={setTabWidth}
                  tabWidth={tabWidth}
                />
              </Box>
            )}
            <Flex width="100%" position={"relative"} top="0">
              <Stack
                flex="1"
                alignItems={"center"}
                borderRadius="2px"
                position={"fixed"}
                right={[0, 0, 0, "0px", "0"]}
                left={[0, 0, 0, "80px", "260px"]}
                zIndex="1022"
              >
                <NavBar
                  showSidebar={showSidebar}
                  setTabWidth={setTabWidth}
                  setShowSidebar={setShowSidebar}
                />
              </Stack>
              <Box
                width={["100%", "100%", "100%", "88%", "80%"]}
                position={"relative"}
                top="100px"
                left={[0, 0, 0, "80px", "260px"]}
                color="#000"
                p={["30px", "30px", "30px", "30px 10px", "30px"]}
                overflowX={"hidden !important"}
              >
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/admin" element={<Admin />} />
                  <Route path="/lenders" element={<Lenders />} />
                  <Route path="/borrowers" element={<Borrowers />} />
                  <Route path="/messages" element={<Messages />} />
                  <Route path="/loan-reports" element={<LoanReports />} />
                  <Route path="/loan-repayments" element={<LoanRepayments />} />
                  <Route path="/repayment-verification" element={<RepaymentVerification />} />
                  <Route
                    path="/account-settings"
                    element={<AccountSettings />}
                  />
                </Routes>
              </Box>
            </Flex>
          </Stack>
        </BrowserRouter>
      </Suspense>
    </UserProfileContextProvider>
  );
}

export default AuthenticatedRoutes;
