import { errorNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getRegisteredUsers = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_USERS);
    setLoading(false);
    // console.log(data, "ALL Users In ONE");
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};
