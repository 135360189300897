import { Box } from "@chakra-ui/react";
import SendOtp from "../components/auth/SendOtp";
import VerifyOtp from "../components/auth/VerifyOtp";
import ResetPassword from "../components/auth/ResetPassword";
import { useState } from "react";

export const ForgotPassword = () => {
  const [navigateTo, setNavigateTo] = useState("sendOtp");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  
  return (
    <Box>
      {navigateTo === "sendOtp" && (
        <SendOtp
          setNavigateTo={setNavigateTo}
          email={email}
          setEmail={setEmail}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {navigateTo === "verifyOtp" && (
        <VerifyOtp
          setNavigateTo={setNavigateTo}
          email={email}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {navigateTo === "resetPassword" && (
        <ResetPassword
          setNavigateTo={setNavigateTo}
          email={email}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </Box>
  );
};
