import React from "react";
import { Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

const TableHead = ({ tableHeadData }) => {
  const location = useLocation();
  return (
    <Thead width="100% !important" overflowX={"scroll"}>
      <Tr width="100% !important">
        {tableHeadData.map((thead, index) => (
          <Tooltip label={thead} key={index}>
            <Th
              // isTruncated
              width="170px !important"
              textTransform={"capitalize !important"}
              fontSize="16px"
              borderRight="1px solid #dadedf !important"
              py={
                location.pathname === "/"
                  ? "20px !important"
                  : "12px !important"
              }
              px={
                location.pathname === "/"
                  ? "25px !important"
                  : "10px !important"
              }
            >
              {thead}
            </Th>
          </Tooltip>
        ))}
      </Tr>
    </Thead>
  );
};

export default TableHead;
