import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useState } from "react";
import { formatToNaira } from "../../utils/numberFormat";
import { initializePayment } from "../../services/loanReports";
import { QueryClient, useMutation } from "@tanstack/react-query";

const LoanReportsDrawerContent = ({ datum, setRefresh }) => {
  const [showText, setShowText] = useState(false);
  const originalText = datum?.userId[0]?.bvnDetails?.bvn;
  const textToAsterisks = originalText?.replace(/./g, "*");
  // const convertToAsterisks = (text) => {
  //   return text.replace(/./g, "*");
  // };
  const handleToggle = () => {
    setShowText(!showText);
  };

  console.log(datum, "rjygrfygr");
  const loan = datum?._id;
  console.log(loan, "hhh");

  const queryClient = new QueryClient();

  const { mutate: createTransactionMutation, isPending: isTransactionLoading } =
    useMutation({
      mutationFn: initializePayment,
      mutationKey: ["create-transaction"],
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["transaction"] });
        window.location.reload();
      },
    });

  const handleCheckout = () => {
    const payload = {
      loanId: loan,
    };
    console.log("Payload", payload);
    createTransactionMutation(payload);
  };

  return (
    <>
      <Flex
        width="100%"
        justify={"space-between"}
        mb="20px"
        mt="40px"
        px="30px !important"
      >
        <Text color="#373737" fontWeight={"semibold"}>
          Borrowers Loan Request
        </Text>
        <Text
          bg={
            datum?.status?.toLowerCase() === "pending"
              ? "#FBF3D4"
              : datum?.status?.toLowerCase() === "approved"
              ? "green.300"
              : "red"
          }
          color={
            datum?.status?.toLowerCase() === "pending" ? "#C9A721" : "#FFFFFF"
          }
          p="2px 10px"
          borderRadius={"10px"}
          textTransform="uppercase"
        >
          {datum?.status}
        </Text>
      </Flex>

      <Box p="20px 30px" bg="#F8F8F8">
        <Flex width="100%" justify={"space-between"} fontSize={"14px"}>
          <Stack color="#B9B7B7" width="50%">
            <Text>Loan Amount</Text>
            <Text>Interest</Text>
            <Text>BVN</Text>
            <Text>Duration</Text>
            <Text>Purpose for Request</Text>
            <Text>Loaning Category</Text>
            <Text>Used Guarantor</Text>
            <Text>Loan Description</Text>
          </Stack>
          <Stack
            color="#373737"
            textTransform="capitalize"
            width={["70%", "70%", "60%", "45%"]}
          >
            <Text>
              {formatToNaira(datum?.agreedLoanDetails?.amount) || "NA"}
            </Text>
            <Text>
              {datum?.agreedLoanDetails?.interestRate
                ? `${datum?.agreedLoanDetails?.interestRate}%`
                : "N/A"}
            </Text>
              <Text onClick={handleToggle}>{showText ? originalText : textToAsterisks}</Text>

            <Text>{datum?.agreedLoanDetails?.duration || "N/A"}</Text>
            <Text>{datum?.loanTitle || "N/A"}</Text>
            <Text>{datum?.loanCategory || "N/A"}</Text>
            <Text>
              {datum?.userId?.[0]?.guarantors?.[0]?.fullName || "N/A"}
            </Text>
            <Text>{datum?.description || "N/A"}</Text>
          </Stack>
        </Flex>
      </Box>

      {/*<Box px="30px" Bg="#fff" mt="30px" mb="20px">
        <Text color="#373737" fontWeight={"semibold"}>
          Used Guarantors
        </Text>
      </Box>
      {datum?.userId?.guarantors?.map((guarantor) => (
        <Flex
          key={guarantor?._id}
          width="100%"
          justify={"space-between"}
          p="20px 30px"
          bg="#F8F8F8"
          fontSize={"14px"}
        >
          <Stack color="#B9B7B7" width="50%">
            <Text>Name</Text>
            <Text>Email</Text>
            <Text>Phone Number</Text>
            <Text>Address</Text>
            <Text>Status</Text>
          </Stack>
          <Stack
            color="#373737"
            width={["70%", "70%", "60%", "45%"]}
            justify={"flex-end"}
          >
            <Text textTransform="capitalize">
              {guarantor?.fullName || "N/A"}
            </Text>
            <Text>{guarantor?.email || "N/A"}</Text>
            <Text>{guarantor?.phoneNumber || "N/A"}</Text>
            <Text>{guarantor?.address || "N/A"}</Text>
            <Text>{guarantor?.isVerified ? "Verified" : "Not Verified"}</Text>
          </Stack>
        </Flex>
      ))}*/}
      <Box px="30px" Bg="#fff" mt="30px" mb="20px">
        <Text color="#373737" fontWeight={"semibold"}>
          Lender Counter Offer
        </Text>
      </Box>
      <Flex
        width="100%"
        justify={"space-between"}
        p="20px 30px"
        bg="#F8F8F8"
        fontSize={"14px"}
      >
        <Stack color="#B9B7B7" width="50%">
          <Text>Amount Disbursed</Text>
          <Text>Disbursed Date</Text>
          {/* <Text>Due Date</Text> */}
          <Text>Total Repayment</Text>
        </Stack>
        <Stack color="#373737" width={["70%", "70%", "60%", "45%"]}>
          <Text>
            {formatToNaira(datum?.agreedLoanDetails?.amount) || "N/A"}
          </Text>
          <Text>
            {datum?.loanDisbursed
              ? dayjs(datum?.disbursedDate).format("MMMM DD YYYY")
              : "N/A"}
          </Text>
          {/* <Text>
            {datum?.loanDisbursed
              ? dayjs(datum?.dueDate).format("MMMM DD YYYY")
              : "N/A"}
          </Text> */}
          <Text>{formatToNaira(datum?.totalAmountDue)}</Text>
        </Stack>
      </Flex>
      {datum?.status.toLowerCase() === "approved" &&
      datum?.directDebitDetails?.active=== true &&
        datum?.lenderTransfer === true && (
          <Flex p="30px" width={"100%"} justify="flex-end">
            <Button
              _hover={{ bg: "#5E6CE7" }}
              p="20px 30px"
              bg="#5E6CE7"
              borderRadius={"25px"}
              color="#fff"
              // onClick={() => handleInitializePayment(datum?._id)}
              // onClick={() => handleCheckout(datum?._id)}
              onClick={handleCheckout}
              isLoading={isTransactionLoading}
              isDisabled={datum?.transferStatus === "success"}
            >
              Disburse
            </Button>
          </Flex>
        )}
      {/* <Modal isOpen={isOpen} onClose={handleCloseCheckout}>
        <ModalOverlay />
        <CheckoutModal
          isOpen={isOpen}
          onClose={handleCloseCheckout}
          selectedPlan={selectedPlan}
          handleCheckout={handleCheckout}
          isLoading={isTransactionLoading}
        />
      </Modal> */}
      {/* <Modal
        closeOnEsc={false}
        isCentered
        size={"xl"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <Flex
            p="5%"
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text fontSize={"1.5em"} fontWeight={700}>
              Payment
            </Text>
            <Button
              bg="#33A0FF"
              _hover={{
                backgroundColor: "#33A0FF",
              }}
              color="#fff"
              onClick={() =>
                axios.put(`/transaction/verify/${paymentReference}`)
                  .then((res) => {
                    successNotifier("Payment Confirmed!");
                    onClose();

                    localStorage.clear();
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch(() => errorNotifier("Error Confirming Payment"))
              }
            >
              I have Paid
            </Button>
          </Flex>

          <ModalBody>
            <iframe width={"100%"} height={"400px"} src={paystackUrl}></iframe>
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};

export default LoanReportsDrawerContent;
