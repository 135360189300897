import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getAdmin = async (setAdmin, setFilteredAdmin, setLoading) => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_ADMIN);
    setLoading(false);
    setAdmin(data?.data?.data);
    setFilteredAdmin(data?.data?.data.filter((data) => !data.isDelete));
  } catch (e) {
    setLoading(false);
  }
};

export const addAdmin = async (payload, setLoading, setRefresh, onClose) => {
  try {
    await http.post(AUTH_ROUTES.ADD_ADMIN, payload);
    setLoading(false);
    successNotifier("Admin successfully added");
    setRefresh((prev) => !prev);
    onClose();
  } catch (e) {
    console.log(e, "error creating admin");
    setLoading(false);
    errorNotifier(e?.response?.data?.errors?.msg);
  }
};
export const updateAdmin =
  (payload, setLoading, setRefresh, adminId) => async (onClose) => {
    try {
      await http.post(AUTH_ROUTES.UPDATE_ADMIN(payload), adminId);
      setLoading(false);
      successNotifier("Admin Updated added");
      setRefresh((prev) => !prev);
      onClose();
    } catch (e) {
      console.log(e, "error updating admin");
      setLoading(false);
      errorNotifier(e?.message);
    }
  };
export const deleteAdmin = async (setLoading, setRefresh, adminId, onClose) => {
  try {
    await http.put(AUTH_ROUTES.DELETE_ADMIN(adminId));
    setLoading(false);
    successNotifier("Admin deleted added");
    setRefresh((prev) => !prev);
    onClose();
  } catch (e) {
    console.log(e, "error deleting admin");
    setLoading(false);
    errorNotifier(e?.response?.data?.errors?.msg);
  }
};
