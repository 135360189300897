import http, { AUTH_ROUTES } from "./api";

export const getNotifications = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.NOTIFICATIONS);
    setLoading(false);
    // console.log(data, "NOTIFICATIONS DATA");
    setData(data);
  } catch (e) {
    setLoading(false);
  }
};
