import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { CustomBtn } from './CustomBtn'
import { verifyConsent } from '../services/loanReports'

const VerifyConsent = ({datum, onClose, setRefresh}) => {
    console.log("oprrh", datum)
    // const loanId= datum?._id
    const reference = datum?.directDebitDetails?.reference
    const [loading, setLoading] = useState(false)
    // console.log(loanId,"ud")
    const [formData, setFormData]= useState({
        "reference":  reference
    })
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload =  {...formData}
        setLoading(true);
        // const idLoan = datum?._id
        await verifyConsent(payload, setLoading, setRefresh,  onClose );
        
      };
  return (
    <Box>
        <Text>confirm verifiction?</Text>
        <Flex justify={"center"} gap={"40px"} mt="20px">
            <CustomBtn text={"cancel"} bg={"red"} handleClick={onClose}/>
            <CustomBtn disabled={datum?.directDebitDetails?.active===true} handleClick={handleSubmit} loading={loading} text={"confirm verification"} bg={"green"}/>
        </Flex>
    </Box>
  )
}

export default VerifyConsent