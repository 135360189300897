import { TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useState } from "react";
import CustomTable from "../table/CustomTable";
import LoanRepaymentsTableBody from "../table/LoanRepaymentTableBody";
import TabHeadings from "./TabHeadings";
import { useEffect } from "react";
import { getSingleLoan } from "../../services/loanRepayments";

const LoanRepaymentsTab = ({
  tabHeadings,
  tableHeadData,
  allLoanRepayments,
  setTabName,
  setFoundLoans,
}) => {
  console.log(allLoanRepayments,"gf")
  const [loading, setLoading] = useState(true);
  // const [singleLoan, setSingleLoan] = useState([])
  //  const loanId = "64246f24bd386c1bbf23010e"
  // useEffect(()=>{
  //   getSingleLoan(setSingleLoan, setLoading, loanId )
  // },[])
  // console.log(singleLoan,",UERFR")
  
  return (
    <Tabs width="100%">
      <TabHeadings
        tabHeadings={tabHeadings}
        setTabName={setTabName}
        setFoundUsers={setFoundLoans}
      />

      <TabPanels width="100% !important">
        <TabPanel width="100% !important" p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <LoanRepaymentsTableBody data={allLoanRepayments} />
          </CustomTable>
        </TabPanel>

        <TabPanel p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <LoanRepaymentsTableBody data={allLoanRepayments} />
          </CustomTable>
        </TabPanel>
        
        <TabPanel p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <LoanRepaymentsTableBody data={allLoanRepayments} />
          </CustomTable>
        </TabPanel>

        <TabPanel p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <LoanRepaymentsTableBody data={allLoanRepayments} />
          </CustomTable>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default LoanRepaymentsTab;
