import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { CustomBtn } from './CustomBtn'
import { approveLoan } from '../services/loanReports'

const ApproveLoan = ({datum, onClose, setRefresh}) => {
    console.log("oprrh", datum)
    const loanId= datum?._id
    const [loading, setLoading] = useState(false)
    console.log(loanId,"ud")
    const [formData, setFormData]= useState({
        "guarantorVerification": true
    })
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload =  {...formData}
        setLoading(true);
        const idLoan = datum?._id
        await approveLoan(payload, setLoading, setRefresh, idLoan, onClose );
        
      };
  return (
    <Box>
        <Text>Are you sure you want to verify this loan?</Text>
        <Flex justify={"center"} gap={"40px"} mt="20px">
            <CustomBtn text={"cancel"} bg={"red"} handleClick={onClose}/>
            <CustomBtn handleClick={handleSubmit} loading={loading} text={"verify loan"} bg={"green"} disabled={datum?.guarantorVerification===true}/>
        </Flex>
    </Box>
  )
}

export default ApproveLoan