import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getBorrowersAndLenders = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_USERS_LIST);
    setLoading(false);
    setData(data?.[0]?.data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};
export const getBorrowers = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_BORROWERS);
    setLoading(false);
    console.log(data, "Borrowers");
    setData(data?.[0]?.data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const getActiveBorrowers = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_ACTIVE_BORROWERS);
    setLoading(false);
    // console.log(data, "ALL ACTIVE BORROWERS ACTIVE In ONE");
    setData(data);
    //return data;
  } catch (e) {
    setLoading(false);
  }
};

export const getPendingBorrowers = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_PENDING_BORROWERS);
    setLoading(false);
    // console.log(data, "ALL DATA In ONE");
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const getUnverifiedBorrowers = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_UNVERIFIED_USERS);
    setLoading(false);
    // console.log(data, "UNVERIFIED");
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const getSuspendedBorrowers = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_SUSPENDED_USERS);
    setLoading(false);
    // console.log(data, "SUSPENDED");
    setData(data);
    return data;
  } catch (e) {
    // console.log(e?.response);
    setLoading(false);
  }
};

export const getPendingLenders = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_PENDING_LENDERS);
    setLoading(false);
    // console.log(data, "ALL LENDER PENDING In ONE");
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const getActiveLenders = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_ACTIVE_LENDERS);
    setLoading(false);
    // console.log(data, "ALL LENDER ACTIVE In ONE");
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const searchUsers = async (
  setData,
  setLoading,
  keyword,
  startDate,
  endDate,
  accountType,
  status
) => {
  try {
    const {
      data: { data },
    } = await http.get(
      AUTH_ROUTES.SEARCH_USERS(keyword, startDate, endDate, accountType, status)
    );
    setLoading(false);
    // console.log(data, "USERS FOUND");
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const searchUsersByDates = async (
  setData,
  setLoading,
  startDate,
  endDate,
  acctType,
  status
) => {
  try {
    const {
      data: { data },
    } = await http.get(
      AUTH_ROUTES.SEARCH_USERS_BY_DATES(startDate, endDate, acctType, status)
    );
    setLoading(false);
    // console.log(data, "USERS FOUND");
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const updateLenderStatus = async (
  statusId,
  status,
  setLoading,
  setRefresh,
  onClose
) => {
   console.log("HEART_", statusId);
  try {
    await http.put(AUTH_ROUTES.UPDATE_LENDER_STATUS(statusId), {
      status,
    });
    successNotifier(`Status changed to ${status}`);
    setRefresh((prev) => !prev);
    onClose();
  } catch (e) {
    if(e.response) {
      errorNotifier(e.response.message);
    }
    // errorNotifier(e?.response);
    console.log(e.response, "update error");
  } finally {
    setLoading(false);
  }
};
