import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React from 'react'
import { AiOutlineSearch } from 'react-icons/ai';

const SearchField = ({ handleSearch }) => {
  return (
    <InputGroup width={["100%", "100%", "100%", "240px", "300px"]}>
      <InputLeftElement
        pointerEvents="none"
        children={<AiOutlineSearch color="lightblue" size={24} />}
      />
      <Input
        bg="#ECECEE"
        color="#140236"
        borderRadius={"35px"}
        type="text"
        placeholder="Search..."
        onChange={handleSearch}
      />
    </InputGroup>
  );
}

export default SearchField
