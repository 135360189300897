import React, { useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { addAdmin } from "../../services/admin";

export const AddAdminDrawerContent = ({ setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [adminType, setAdminType] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      name: ` ${firstName} ${lastName} `,
      email,
      adminType,
    };

    addAdmin(payload, setLoading, setRefresh, onClose);
  };

  return (
    <>
      <Button
        onClick={onOpen}
        bg="#5E6CE7"
        color={"#fff"}
        borderRadius="25px"
        w={["100%", "100%","200px", "200px"]}
        mt={["10px", "10px", "0px", "0px"]}
        _hover={{
          bg: "#5E6CE7",
        }}
      >
        Add Admin
      </Button>
      <Drawer
        size={"lg"}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          {/* <DrawerCloseButton /> */}
          <Flex
            width="100%"
            align={"center"}
            justify={"space-between"}
            boxShadow="md"
            p="20px 30px !important"
          >
            <Text fontSize={"20px"} color={"#032A34"} fontWeight="semibold">
              Add New Admin
            </Text>
            <Button
              bg="#5E6CE7"
              px={"30px"}
              color={"#fff"}
              borderRadius="25px"
              ref={btnRef}
              onClick={onClose}
              _hover={{
                bg: "#5E6CE7",
              }}
            >
              Cancel
            </Button>
          </Flex>

          <DrawerBody p="20px 30px !important">
            <Flex justify={"space-between"} align={"center"} w={"100%"}>
              <Text color={"#373737"} fontWeight={"semibold"} fontSize={"18px"}>
                Admin Personal Information
              </Text>
            </Flex>

            <Flex
              w={"100%"}
              mt={"50px"}
              direction={["column", "column", "row"]}
            >
              <FormControl mr={["0px", "0px", "30px"]}>
                <FormLabel color={"#373737"} fontWeight={400} fontSize={"16px"}>
                  First Name
                </FormLabel>
                <Input
                  type="text"
                  bg={"#FFFFFF"}
                  h={"50px"}
                  value={firstName}
                  onChange={(e) => setfirstName(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  color={"#373737"}
                  fontWeight={400}
                  fontSize={"16px"}
                  pt={["20px", "20px", "0px"]}
                >
                  Last Name
                </FormLabel>
                <Input
                  type="text"
                  bg={"#FFFFFF"}
                  h={"50px"}
                  value={lastName}
                  onChange={(e) => setlastName(e.target.value)}
                />
              </FormControl>
            </Flex>

            <Flex
              w={"100%"}
              mt={["20px", "20px", "30px"]}
              mb={["50px"]}
              direction={["column", "column", "row"]}
            >
              <FormControl mr={["0px", "0px", "30px"]}>
                <FormLabel color={"#373737"} fontWeight={400} fontSize={"16px"}>
                  Email Address
                </FormLabel>
                <Input
                  type="email"
                  bg={"#FFFFFF"}
                  h={"50px"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl mr={["0px", "0px", "30px"]}>
                <FormLabel color={"#373737"} fontWeight={400} fontSize={"16px"}>
                  Admin Type
                </FormLabel>
                <Select
                  placeholder="Select Admin Type"
                  bg={"#FFFFFF"}
                  h={"50px"}
                  value={adminType}
                  onChange={(e) => setAdminType(e.target.value)}
                >
                  <option value="superAdmin">superAdmin</option>
                  <option value="regularAdmin">regularAdmin</option>
                </Select>
              </FormControl>
            </Flex>
            <Flex mb={["30px", "30px", "0px"]} justify={"flex-end"}>
              <Button
                bg="#5E6CE7"
                color={"#fff"}
                borderRadius="25px"
                px="30px"
                mt={["10px", "10px", "10px", "0px"]}
                isLoading={loading}
                isDisabled={!firstName || !lastName || !email || !adminType}
                _hover={{
                  bg: "#5E6CE7",
                }}
                onClick={handleSubmit}
              >
                Add Admin
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
