import React from "react";
import { Box, Table } from "@chakra-ui/react";
import { dashboardTableHead } from "../../../table.constants";
import TableHead from "../TableHead";
import DashboardTableBody from "./DashboardTableBody";

const DashoardTable = ({ users, handleUpdate, setRefresh, btnLoading }) => {
  return (
    <Box className="table-container" width="100%" overflowX={"scroll"}>
      <Table
        variant="striped"
        colorScheme="gray"
        bg="#fff"
        borderRadius={"20px"}
      >
        <TableHead tableHeadData={dashboardTableHead} />
        <DashboardTableBody
          data={users}
          handleUpdate={handleUpdate}
          setRefresh={setRefresh}
          btnLoading={btnLoading}
        />
      </Table>
    </Box>
  );
};

export default DashoardTable;
