import { Box, Flex, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
export const NotificationCard = ({ data, setRefresh }) => {
  const relativeTime = require("dayjs/plugin/relativeTime");
  dayjs.extend(relativeTime);

  
  return (
    <Box p="10px" color="#929292">
      <Flex alignItems={"center"} mb="5px">
        <Box
          ml="20px"         
        >
          <Text color="#352848" fontWeight="bold" fontSize=".9em">
            {data?.title}
          </Text>
          <Text fontSize=".87em">{data?.message}</Text>
          <Text fontSize={".7em"}>{dayjs(data?.createdAt).from(dayjs())}</Text>
        </Box>
      </Flex>
    </Box>
  );
};
