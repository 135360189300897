import React, { useState } from "react";
import {
  Avatar,
  Box,
  Flex,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { AiOutlineEye } from "react-icons/ai";
import { BiMessageDetail } from "react-icons/bi";
import { formatToNaira } from "../../utils/numberFormat";
import CustomDrawer from "../Drawer";
import MessageBox from "../messageInfo/MessageBox";
import LendersDrawerContent from "../drawerChildren/LendersDrawerContent";

const TableBody = ({
  data,
  handleUpdate,
  btnLoading,
}) => {
  const [types, setTypes] = useState([]);
  return (
    <Tbody px="0 !important">
      {data?.map((datum) => (
        <Tr key={datum?._id} fontSize={"16px"}>
          <Tooltip label={datum?.fullName}>
            <Td
              maxWidth="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Avatar
                size={"sm"}
                src={datum?.image}
                alt={`Avatar of ${datum?.fullName}`}
                name={datum?.fullName}
              />
            </Td>
          </Tooltip>
          <Tooltip label={datum?.fullName} textTransform={"capitalize"}>
            <Td
              isTruncated
              maxWidth="170px !important"
              px="10px !important"
              textTransform={"capitalize"}
              borderRight="1px solid #dadedf !important"
            >
              {datum?.fullName}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.email}>
            <Td
              isTruncated
              maxWidth="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.email}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.phoneNumber}>
            <Td
              isTruncated
              maxWidth="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.phoneNumber}
            </Td>
          </Tooltip>
          <Tooltip label={dayjs(datum?.createdAt).format("DD MMM YYYY")}>
            <Td
              isTruncated
              maxWidth="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {dayjs(datum?.createdAt).format("DD MMM YYYY")}
            </Td>
          </Tooltip>
          <Tooltip
            label={formatToNaira(datum?.disbursedAmount) || "N/A"}
          >
            <Td
              isTruncated
              maxWidth="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {formatToNaira(datum?.disbursedAmount) || "N/A"}
            </Td>
          </Tooltip>
          <Tooltip label={formatToNaira(datum?.borrowerRepaidAmount) || "N/A"}>
            <Td
              isTruncated
              maxWidth="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {formatToNaira(datum?.borrowerRepaidAmount) || "N/A"}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.status}>
            <Td
              color="#C9A721"
              isTruncated
              maxWidth="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Text
                textAlign={"center"}
                bg={
                  datum?.status?.toLowerCase() === "pending"
                    ? "#FBF3D4"
                    : datum?.status?.toLowerCase() === "active"
                    ? "green.300"
                    : datum?.status?.toLowerCase() === "unverified"
                    ? "pink.300"
                    : "red"
                }
                color={
                  datum?.status?.toLowerCase() === "pending"
                    ? "#C9A721"
                    : "#FFFFFF"
                }
                p="3px 10px"
                borderRadius={"10px"}
              >
                {datum?.status}
              </Text>
            </Td>
          </Tooltip>
          <Td isTruncated maxWidth="170px !important" px="10px !important">
            <Flex justify={"space-between"}>
              <CustomDrawer
                icon={AiOutlineEye}
                header={`${datum?.accountType} Details`}
                iconColor={"#5E6CE7"}
                data={data}
              >
                <LendersDrawerContent
                  datum={datum}
                  handleUpdate={handleUpdate}
                  btnLoading={btnLoading}
                  status={datum?.status === "active" ? "Suspended" : "Active"}
                />
              </CustomDrawer>
              <CustomDrawer
                icon={BiMessageDetail}
                header={"Individual Message"}
                iconColor={"#5E6CE7"}
                data={data}
              >
                <Box mt="40px">
                  <MessageBox
                    recipient={[datum]}
                    selectedItems={[datum?.userId?._id]}
                    types={types}
                    setTypes={setTypes}
                  />
                </Box>
              </CustomDrawer>
            </Flex>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default TableBody;
