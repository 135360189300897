import React from "react";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  HStack,
  RadioGroup,
  Text,
} from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import { postMessages } from "../../services/message";

function MessageTextareas({ recipient, types, selectedItems }) {
  const [msg, setMsg] = useState("");
  const [resize, setResize] = useState("none");
  const [loading, setLoading] = useState(false);
  const remaining = recipient?.length - 3;

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      recipientId: selectedItems,
      type: types,
      msg,
    };
    await postMessages(payload, setLoading, setMsg);
  };
  return (
    <Box>
      <RadioGroup defaultValue={resize} onChange={setResize}></RadioGroup>
      <Box>
        <HStack bg="#F1F2FF" align="center" borderTopRadius="6px" py="10px">
          <Text fontSize="14px" color="#264653" fontWeight="bold" pl="12px">
            Sending To :
          </Text>
          {recipient?.slice(0, 3)?.map((rec) => (
            <>
              <Avatar
                src={rec?.image}
                size="sm"
                alt={`Avatar of ${
                  rec?.fullName ||
                  rec?.userId?.fullName ||
                  rec?.userId?.[0]?.fullName
                }`}
                name={
                  rec?.fullName ||
                  rec?.userId?.fullName ||
                  rec?.userId?.[0]?.fullName
                }
              />
              <Text color="#264653" fontWeight="bold" fontSize="14px">
                {rec?.fullName ||
                  rec?.userId?.fullName ||
                  rec?.userId?.[0]?.fullName}{" "}
              </Text>
            </>
          ))}
          <Text
            as="span"
            fontSize={"14px"}
            color="green"
            fontWeight={"semibold"}
          >
            {recipient?.length > 3 && `+${remaining} Others`}
          </Text>
        </HStack>
        <Textarea
          name="message"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          size="sm"
          resize={resize}
          h={["100px", "100px", "200px"]}
          borderBottomRadius="6px"
          bg="#F1F2FF"
          border="none"
        />
        <Button
          colorScheme="blue"
          py="14px"
          px="47px"
          borderRadius="24px"
          mt="32px"
          fontSize="14px"
          isLoading={loading}
          onClick={handleSubmit}
          isDisabled={!msg || types.length < 1 || selectedItems.length < 1}
        >
          Send Message
        </Button>
      </Box>
    </Box>
  );
}

export default MessageTextareas;
