import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlinePersonSearch } from "react-icons/md";
import { AiOutlineClose, AiOutlineSetting } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { GoClippy } from "react-icons/go";
import { CgLogOut } from "react-icons/cg";
import { RiDashboardLine } from "react-icons/ri";
import { BsPeople } from "react-icons/bs";
import { RiAdminLine } from "react-icons/ri";
import { BiMessageDetail } from "react-icons/bi";
import { HiOutlineClipboardList } from "react-icons/hi";
import logo from "../assets/logo.png";
import CustomDrawer from "./Drawer";
import NotificationContent from "./notifications/NotificationContent";

const SideBar = ({ setShowSidebar, showSidebar, setTabWidth, tabWidth }) => {
  const navList = [
    {
      text: "Dashboard",
      link: "/",
      icon: RiDashboardLine,
    },
    {
      text: "Admins",
      link: "/admin",
      icon: RiAdminLine,
    },
    {
      text: "Lenders",
      link: "/lenders",
      icon: MdOutlinePersonSearch,
    },
    {
      text: "Borrowers",
      link: "/borrowers",
      icon: BsPeople,
    },
    {
      text: "Messages",
      link: "/messages",
      icon: BiMessageDetail,
    },
    {
      text: "Loan Reports",
      link: "/loan-reports",
      icon: HiOutlineClipboardList,
    },
    {
      text: "Loan Repayments",
      link: "/loan-repayments",
      icon: GoClippy,
    },
    {
      text: "Account Settings",
      link: "/account-settings",
      icon: AiOutlineSetting,
    },
  ];

  const handleLogOut = () => {
    sessionStorage.removeItem("#WUEPP#");
    window.location.reload();
  };

  return (
    <Stack
      spacing={"5px"}
      h="100vh"
      alignItems={"flex-start"}
      bg="#0D1139"
      color="#fff"
      p="20px"
    >
      <Flex
        mt="20px"
        mb="40px"
        justify={["space-between", "space-between", "center"]}
        align="center"
        w="100%"
      >
        <Link to="/">
          <Image
            src={logo}
            alt="WhoUeppLogo"
            ml={["20px", "", "", "0px", "-30px"]}
            width={["80px", "80px", "120px"]}
          />
        </Link>
        {window.innerWidth < 900 ? (
          <AiOutlineClose
            cursor={"pointer"}
            size={24}
            onClick={() => {
              setShowSidebar(!showSidebar);
              // setTabWidth("80px");
            }}
          />
        ) : window.innerWidth > 900 && window.innerWidth <= 1100 ? (
          <AiOutlineClose
            cursor={"pointer"}
            size={24}
            onClick={() => {
              setShowSidebar(true);
              setTabWidth("80px");
            }}
          />
        ) : null}
      </Flex>
      <style>{`
      .notActive .icon-class{
        color: #fff;
      }
      `}</style>
      {navList.map(({ text, icon: Icon, link }, id) => {
        return (
          <NavLink
            key={id}
            to={link}
            className={({ isActive }) =>
              isActive ? "activeClass" : "notActive"
            }
            style={({ isActive }) =>
              isActive
                ? {
                    borderTopLeftRadius: "25px",
                    borderBottomLeftRadius: "25px",
                    background: "#fff",
                    color: "#1A0031",
                    padding: "10px 20px",
                    width: window.innerWidth >= "1100px" ? "240px" : "100%",
                    textAlign: "left",
                    fontWeight: "bold",
                  }
                : {
                    color: "#fff",
                    padding: "10px 20px",
                    fontWeight: "bold",
                  }
            }
            onClick={() => window.innerWidth < 600 && setShowSidebar(false)}
          >
            <Stack
              key={id}
              direction={"row"}
              spacing="10px"
              alignItems={"center"}
            >
              <Box>
                <Icon className="icon-class" fontSize={"1.2rem"} />
              </Box>

              <Text
                display={
                  tabWidth === "80px" &&
                  window.innerWidth < 1100 &&
                  window.innerWidth > 600
                    ? "none"
                    : "block"
                }
                fontWeight={"normal"}
                fontSize={[
                  "0.85rem",
                  "0.85rem",
                  "0.85rem",
                  "0.85rem",
                  "0.96rem",
                ]}
              >
                {text}
              </Text>
            </Stack>
          </NavLink>
        );
      })}
      <Flex align="center" py="10px" px="20px" gap="10px">
        <CustomDrawer
          icon={IoMdNotificationsOutline}
          header={"Notifications"}
          title={
            tabWidth === "80px" &&
            window.innerWidth < 1100 &&
            window.innerWidth > 600
              ? ""
              : "Notifications"
          }
        >
          <NotificationContent />
        </CustomDrawer>
      </Flex>
      <Box p="30px 40px" position={"absolute"} bottom="0" color="#fff" left="0">
        <Stack
          direction={"row"}
          align="center"
          cursor={"pointer"}
          color="#fff"
          onClick={handleLogOut}
        >
          <CgLogOut size={24} />
          <Text
            display={
              tabWidth === "80px" &&
              window.innerWidth < 1100 &&
              window.innerWidth > 600
                ? "none"
                : "block"
            }
          >
            Log out
          </Text>
        </Stack>
      </Box>
    </Stack>
  );
};

export default SideBar;
