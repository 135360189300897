import { Box, Text, Flex, Avatar, Tooltip } from "@chakra-ui/react";
import { Checkbox } from "@chakra-ui/react";

function MessageLenderInputs({
  data,
  setCheck,
  setRecipient,
  checkedItems,
  handleCheckboxChange,
}) {
  //console.log("checked", checkedItems, data[0]._id);
  return (
    <Box>
      {data?.map((item) => (
        <Box key={item?._id} mb="10px">
          <Flex align="center" gap="10px">
            <Checkbox
              colorScheme="green"
              size={"lg"}
              value={item._id}
              isChecked={checkedItems[item._id]}
              onChange={() => {
                handleCheckboxChange(item._id);
                console.log(item, "item", item._id);
              }}
              // onChange={(e) => {
              //   handleCheckboxChange(data, item._id);
              //   // setRecipient(prev => [...prev, item]);
              //    setRecipient((prevSelectedItems) => {
              //      if (prevSelectedItems.find(pre => pre?._id === item?._id)) {
              //        return prevSelectedItems.filter((id) => id?._id !== item?._id);
              //      } else {
              //        return [...prevSelectedItems, item];
              //      }
              //    });
              //   setCheck(false);
              // }}
            />
            <Box>
              <Avatar
                name={item?.fullName}
                src={item?.image}
                alt=""
                width="42px"
                height="42px"
              />
            </Box>
            <Box fontSize="13px" color="#011714" fontWeight="semibold">
              <Tooltip label={item?.fullName}>
                <Text isTruncated maxW="300px" textTransform={"capitalize"}>
                  {item?.fullName}
                </Text>
              </Tooltip>

              <Tooltip label={item?.email}>
                <Text isTruncated maxW="300px">
                  {item?.email}
                </Text>
              </Tooltip>
            </Box>
          </Flex>
        </Box>
      ))}
    </Box>
  );
}

export default MessageLenderInputs;
