import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import FilterSection from "../components/FilterSection";
import FullPageLoader from "../components/fullPageLoader";
import { Pagination } from "../components/Pagination";
import CustomTab from "../components/tabs/CustomTab";
import {
  getActiveBorrowers,
  getBorrowers,
  getBorrowersAndLenders,
  getSuspendedBorrowers,
  getUnverifiedBorrowers,
  searchUsers,
  searchUsersByDates,
  updateLenderStatus,
} from "../services/borrowersAndLenders";
import { borrowersTableHead, borrowersTabTitles } from "../table.constants";
import PageHeader from "../components/PageHeader";

const Borrowers = () => {
  // const { usersData } = useGetState();
  const [usersData, setUsersData] = useState([]);
  const [foundUsers, setFoundUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [name, setName] = useState("");
  const [activeBorrowers, setActiveBorrowers] = useState([]);
  const [suspendedUsers, setSuspendedUsers] = useState([]);
  const [unVerifiedUsers, setUnVerifiedUsers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [tabName, setTabName] = useState("All Status");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage] = useState(20);

  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;

  const dataByTabName =
    tabName === "Active"
      ? activeBorrowers
      : tabName === "Unverified"
      ? unVerifiedUsers
      : tabName === "Suspended"
      ? suspendedUsers
      : usersData;
  const users = foundUsers.length > 0 ? foundUsers : dataByTabName || [];

  const currentRecord = users?.slice(indexOfFirstRecord, indexOfLastRecord);
  const total =
    recordPerPage * currentPage + currentRecord?.length - recordPerPage;
console.log("alllllll", usersData.allBorrowers)
  const handleSearch = (e) => {
    const borrowers = "borrower";
    searchUsers(
      setFoundUsers,
      setLoading,
      e.target.value,
      startDate,
      endDate,
      borrowers,
      tabName
    );
  };

  const handleSearchByDate = (e) => {
    searchUsersByDates(
      setFoundUsers,
      setLoading,
      startDate,
      e.target.value,
      "borrower",
      tabName
    );
  };

  useEffect(() => {
    getBorrowersAndLenders(setUsersData, setLoading);
    getBorrowers(setUsersData, setLoading);
    getActiveBorrowers(setActiveBorrowers, setLoading);
    getUnverifiedBorrowers(setUnVerifiedUsers, setLoading);
    getSuspendedBorrowers(setSuspendedUsers, setLoading);
  }, [refresh]);

  const handleUpdate = async (id, status, onClose) => {
    setBtnLoading(true);
    await updateLenderStatus(id, status, setBtnLoading, setRefresh, onClose);
  };
  console.log(usersData, "show", )
  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width="100%">
      <PageHeader title="Borrowers" />
      <Box>
        <FilterSection
          handleSearch={handleSearch}
          handleSearchByDate={handleSearchByDate}
          startDate={startDate}
          endDate={endDate}
          name={name}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setName={setName}
          usersData={users}
          tabName={tabName}
          activeLenders={activeBorrowers}
          unverified={unVerifiedUsers}
          suspended={suspendedUsers}
          page={"userTable"}
        />
      </Box>
      <Box
        bg={"#fff"}
        borderRadius="10px"
        mt="20px"
        width={"100%"}
        // overflow={"scroll"}
      >
        <CustomTab
          setTabName={setTabName}
          setFoundUsers={setFoundUsers}
          data={currentRecord}
          tabHeadings={borrowersTabTitles}
          tableHeadData={borrowersTableHead}
          handleUpdate={handleUpdate}
          btnLoading={btnLoading}
        />
      </Box>

      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {total} of {users.length} Users
        </Text>
        <Pagination
          totalData={users.length ?? 0}
          dataPerPage={recordPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};

export default Borrowers;
