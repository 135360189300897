import React, { useState } from "react";
import {
  Box,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import accountbanner from "../../assets/accountbanner.png";
import icon from "../../assets/edit@2x.png";
import { updateProfileImage } from "../../services/accountSettings";
import { useGetState } from "../../contexts/useGetState";

export const BannerSecton = ({ userInfo, setRefresh }) => {
  const { dispatch } = useGetState();
  const [loading, setLoading] = useState(false);

  const handleChange = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("profileImage", e.target.files?.[0]);
    await updateProfileImage(formData, setLoading, setRefresh).then(() =>
      dispatch({ reload: (prev) => !prev })
    );
  };

  return (
    <Flex
      w="100%"
      bg="#fff"
      borderRadius="10px"
      bgImage={accountbanner}
      py="19px"
      px="33px"
      bgPosition="right"
      bgRepeat="no-repeat"
    >
      <Flex gap="26px" align="center" direction={["column", "column", "row"]}>
        <HStack>
          <Image
            src={userInfo?.image}
            width="114px"
            height="114px"
            borderRadius="50%"
            position="relative"
          />
          <FormLabel htmlFor="profile-pic" borderRadius={"50%"}>
            {" "}
            <Image
              // onClick={handleClick}
              src={icon}
              bg="blue"
              w="26px"
              h="26px"
              borderRadius="50%"
              position="absolute"
              left="150px"
              top="130px"
              cursor="pointer"
            />
          </FormLabel>
          <Input
            display={"none"}
            type="file"
            id="profile-pic"
            onChange={handleChange}
          />

          {loading && (
            <Box position={"absolute"} top="11%" left="8%" zIndex={1000}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Box>
          )}
        </HStack>
        <Box>
          <Heading pb="9px" fontSize={["25px", "25px", "30px"]} color="#00061A">
            {userInfo?.name}
          </Heading>
          <Text fontSize="20px" color="#616161">
            {userInfo?.email}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
