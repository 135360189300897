export const AUTH_ROUTES = {
  LOGIN: "/auth/login",
  GET_LOGGED_IN_ADMIN: "/admin/me",

  //FORGOT PASSWORD
  SEND_OTP: "/auth/otp",
  VERIFY_OTP: "/auth/otp/verify",
  RESET_PASSWORD: "/auth/reset-admin-password",

  // PROFILE
  UPDATE_PROFILE: (userId) => `admin/profile/${userId}`,
  UPDATE_PASSWORD: `admin/password`,
  UPDATE_IMAGE: `admin/image/upload`,

  //ADMIN
  GET_ADMIN: `/admin/get/admin?sort=desc`,
  ADD_ADMIN: `/admin?sort=desc`,
  UPDATE_ADMIN: (adminId) => `/admin/profile/${adminId}`,
  DELETE_ADMIN: (adminId) => `/admin/delete/${adminId}`,

  // USERS
  GET_USERS: `admin/users`,
  GET_PENDING_BORROWERS: `/admin/users?accountType=borrower&status=pending`,
  GET_PENDING_LENDERS: `/admin/users?accountType=lender&status=pending`,
  GET_ACTIVE_BORROWERS: `/admin/users?accountType=borrower&status=Active`,
  GET_UNVERIFIED_USERS: `/admin/users?status=Unverified`,
  GET_SUSPENDED_USERS: `/admin/users?accountType=borrower&status=Suspended`,
  GET_ACTIVE_LENDERS: `/admin/users?accountType=lender&status=Active`,
  SEARCH_USERS: (keyword, startDate, endDate, accountType, status) =>
    `admin/users/search?search=${keyword}&accountType=${accountType}&startDate=${startDate}&endDate=${endDate}`,
  SEARCH_USERS_BY_DATES: (startDate, endDate, accountType, status) =>
    `admin/users/search?startDate=${startDate}&endDate=${endDate}&accountType=${accountType}`,
  GET_USERS_LIST: `admin/users/list`,
  GET_BORROWERS: `admin/borrowers/details`,
  UPDATE_LENDER_STATUS: (statusId) => `admin/user/profile/${statusId}`,

  //LOAN REPORTS
  GET_LOANS: `/loan/multiple/loans`,
  VERIFY_CONSENT: "/transaction/verify-direct-debit",
  VERIFY_LOAN:(loanId)=> `/admin/verify-guarantor/${loanId}`,
  CONSENT_REMINDER:"/transaction/debit-reminder",
  GET_LOAN_DETAILS: (loanId)=> `/admin/loan-details?_id=${loanId}`,
  GET_PENDING_LOANS: `/loan/multiple/loans?status=pending`,
  GET_APPROVED_LOANS: `/loan/multiple/loans?status=approved`,
  GET_DUE_LOANS: `/loan/multiple/loans?status=due`,
  SEARCH_LOAN_REPORTS: (keyword) => `/admin/loans/search?search=${keyword}`,
  SEARCH_LOANS_BY_DATES: (startDate, endDate, stat, status) =>
    `/admin/loans/search?startDate=${startDate}&endDate=${endDate}&${stat}=${status}`,

  //NOTIFICATIONS
  NOTIFICATIONS: "/notification",

  //LOAN REPAYMENTS
  GET_REPAYMENT:"/loan-repayment",
  GET_SINGLE_LOAN: (loanId)=>`/loan/singleloan/${loanId}`,
  GET_PENDING_REPAYMENTS: `/loan/multiple/loans?status=approved&repaymentStatus=active`,
  GET_COMPLETED_REPAYMENTS: `/loan/multiple/loans?status=approved&repaymentStatus=completed`,
  GET_DUE_REPAYMENTS: `/loan/multiple/loans?status=approved&repaymentStatus=due`,
  GET_OVERDUE_REPAYMENT: "/admin/over-due-loan",
  VERIFY_REPAYMENT: "/transaction/verify-debit-charge",
  INITIATE_DIRECT_DEBIT: "/transaction/direct-debit-charge",
  SEARCH_ACTIVE_LOAN_REPAYMENTS_BY_DATES: (
    startDate,
    endDate,
    repaymentStatus,
    status
  ) =>
    `/admin/loans/search?startDate=${startDate}&endDate=${endDate}&${repaymentStatus}=${status}`,
  SEARCH_ACTIVE_LOAN_REPAYMENTS: (keyword) =>
    `/admin/loans/search?search=${keyword}&status=approved`,
  DISBURSE_LOAN: (loanId) => `/loan/disbursed-loan/${loanId}`,


//TANSACTION
INITIATE_PAYMENT:`/transaction/create-recipient`,
};
