import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ForgotPassword } from "../pages/ForgotPassword";

function UnAuthenticatedRoutes(props) {
  const LoginPage = lazy(() => import("../pages/LoginPage"));

  return (
    <Suspense fallback={<p></p>}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/*" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default UnAuthenticatedRoutes;
