import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getLoanReports = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_LOANS);
    setLoading(false);
    // console.log(data, "LOAN REPORTS");
    setData(data);
  } catch (e) {
    setLoading(false);
  }
};

export const getLoanDetails = async (setData, setLoading, loanId) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_LOAN_DETAILS(loanId));
    setLoading(false);
    console.log(data, "LOAN DETAILS");
    setData(data);
  } catch (e) {
    setLoading(false);
  }
};





export const getPendingLoanReports = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_PENDING_LOANS);
    setLoading(false);
    // console.log(data, "PENDING LOAN REPORTS");
    setData(data);
  } catch (e) {
    setLoading(false);
  }
};

export const getApprovedLoanReports = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_APPROVED_LOANS);
    setLoading(false);
    // console.log(data, "APPROVED LOAN REPORTS");
    setData(data);
  } catch (e) {
    setLoading(false);
  }
};

export const getRepayment= async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_REPAYMENT);
    setLoading(false);
    // console.log(data, "APPROVED LOAN REPORTS");
    setData(data);
  } catch (e) {
    setLoading(false);
  }
};

export const getDueLoanReports = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_DUE_LOANS);
    setLoading(false);
    // console.log(data, "DUE LOAN REPORTS");
    setData(data);
  } catch (e) {
    setLoading(false);
  }
};

export const searchAllLoanReports = async (
  setData,
  setLoading,
  keyword
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.SEARCH_LOAN_REPORTS(keyword));
    setLoading(false);
    // console.log(data, "LOANS FOUND");
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const searchLoanReportsByDates = async (
  setData,
  setLoading,
  startDate,
  endDate,
  stat,
  status
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.SEARCH_LOANS_BY_DATES(startDate, endDate, stat, status));
    setLoading(false);
    // console.log(data, "LOANS FOUND");
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
  }
};

export const approveLoan = async (
  payload,
  setLoading,
  setRefresh,
  loanId,
  onClose
) => {
  try {
    await http.put(AUTH_ROUTES.VERIFY_LOAN(loanId), payload);
    successNotifier("Updated Successfully");
    setLoading(false);
    setRefresh((prev) => !prev);
    onClose()
  } catch (e) {
    console.log(e, "Approver error");
    errorNotifier(
      e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors?.msg
    );
    setLoading(false);
    onClose()
  }
};

export const verifyConsent = async (
  payload,
  setLoading,
  setRefresh,
  onClose
) => {
  try {
    await http.post(AUTH_ROUTES.VERIFY_CONSENT, payload);
    successNotifier("Verification Successfull");
    setLoading(false);
    setRefresh((prev) => !prev);
    onClose()
  } catch (e) {
    console.log(e, "Approver error");
    errorNotifier(
      e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors?.msg
    );
    setLoading(false);
    onClose()
  }
};

// export const initializePayment = async (payload) => {
//   try {
//     const { data } = await http.post(AUTH_ROUTES.INITIATE_PAYMENT, payload);
//     if (data.success && data?.data && data?.data?.data?.authorizationUrl) {
//       window.location.href = data?.data?.data?.authorizationUrl;
      
//       // window.open(data?.data?.data?.authorizationUrl, "_blank");
//       successNotifier("Transaction initiated successfully");


//       console.log(data,"TransINIT")
//     } else {
//       errorNotifier("Failed to initiate transaction");
//     }
//   } catch (e) {
//     if (e?.response) {
//       errorNotifier(
//         console.log(
//           e.response?.data?.message || e.response?.data?.data?.message
//         )
//       );
//     }
//     //  else {
//     //   errorNotifier("Network Error, please check your internet connections");
//     // }
//   }
// };


export const initializePayment = async (
  payload,
) => {
  try {
    await http.post(AUTH_ROUTES.INITIATE_PAYMENT, payload);
    successNotifier("Disbursed Successfully");
    // setLoading(false);
    // setRefresh((prev) => !prev);
  } catch (e) {
    console.log(e, "Disbursement error");
    errorNotifier(
      // "Disbursed Successfully"
      e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors?.msg
    );
    // setLoading(false);
  }
};
export const consentReminder = async (
  payload,
) => {
  try {
    await http.post(AUTH_ROUTES.CONSENT_REMINDER, payload);
    successNotifier("Reminder Sent Successfully");
    // setLoading(false);
    // setRefresh((prev) => !prev);
  } catch (e) {
    console.log(e, "Disbursement error");
    errorNotifier(
      // "Disbursed Successfully"
      e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors?.msg
    );
    // setLoading(false);
  }
};

