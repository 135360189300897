import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { FormInputs } from "../FormInput";
import { updatePassword } from "../../services/accountSettings";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const UpdatePassword = ({ userInfo, setRefresh }) => {
  const [formValues, setFormValues] = useState({
    prevPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    setLoading(true);
    const payload = {
      ...formValues,
      id: userInfo?._id,
    };
    await updatePassword(payload, setLoading, setRefresh);
  };

  return (
    <Box
      gap="26px"
      align="center"
      w="100%"
      bg="#fff"
      py="19px"
      px="33px"
      my="15px"
      borderRadius="10px"
    >
      <Text
        align="start"
        pt="10px"
        pb="42px"
        fontSize="20px"
        color="#1A0031"
        fontWeight="bold"
      >
        Account
      </Text>
      <Flex
        gap={["10px", "10px", "59px"]}
        direction={["column", "column", "row"]}
      >
        <InputGroup>
          <FormInputs
            name={"prevPassword"}
            value={formValues?.prevPassword}
            handleChange={handleChange}
            type={show ? "text" : "password"}
            label={"Current Password"}
          />
          <InputRightElement
            top={"40px"}
            children={
              <span
                style={{ cursor: "pointer", padding: "5px" }}
                onClick={handleClick}
              >
                {show ? <FaEye /> : <FaEyeSlash />}
              </span>
            }
          />
        </InputGroup>
        <InputGroup>
          <FormInputs
            name={"password"}
            value={formValues?.password}
            handleChange={handleChange}
            type={show ? "text" : "password"}
            label={"New Password"}
          />
          <InputRightElement
            top={"40px"}
            children={
              <span
                style={{ cursor: "pointer", padding: "5px" }}
                onClick={handleClick}
              >
                {show ? <FaEye /> : <FaEyeSlash />}
              </span>
            }
          />
        </InputGroup>
        <InputGroup>
          <FormInputs
            name={"confirmPassword"}
            value={formValues?.confirmPassword}
            handleChange={handleChange}
            type={show ? "text" : "password"}
            label={"Confirm Password"}
          />
          <InputRightElement
            top={"40px"}
            children={
              <span
                style={{ cursor: "pointer", padding: "5px" }}
                onClick={handleClick}
              >
                {show ? <FaEye /> : <FaEyeSlash />}
              </span>
            }
          />
        </InputGroup>
      </Flex>
      <Flex justify="flex-end">
        <Button
          colorScheme="blue"
          borderRadius="24px"
          mt="34px"
          py="12px"
          px="28px"
          isDisabled={
            formValues?.password !== formValues?.confirmPassword ||
            !formValues?.prevPassword ||
            !formValues?.confirmPassword
          }
          isLoading={loading}
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Flex>
    </Box>
  );
};
