import {
  Avatar,
  Box,
  Button,
  Flex,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { formatToNaira } from "../../utils/numberFormat";
import { BsThreeDots } from "react-icons/bs";
import CustomDrawer from "../Drawer";
import LoanReportsDrawerContent from "../drawerChildren/LoanReportsDrawerContent";
import { MdBuild } from "react-icons/md";
import { approveLoan } from "../../services/loanReports";
import CustomModal from "../CustomModal";
import { CustomBtn } from "../CustomBtn";
import ApproveLoan from "../ApproveLoan";
import { MoreActionButton } from "../MoreActionButton";

const LoanReportsTableBody = ({ data, setRefresh }) => {
  console.log("dAata", data?._id)
  const clickRef = useRef(null);
  const [dis, setDis] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleApprove = async () => {
    await approveLoan(setRefresh, onClose);
  };
  return (
    <Tbody>
      {data?.map((datum, key) => (
        <Tr key={key} fontSize={"16px"} width="100%">
          <Tooltip label={datum?.loanId}>
            <Td
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.loanId || "N/A"}
            </Td>
          </Tooltip>
          <Tooltip
            label={datum?.userId?.fullName || datum?.userId?.[0]?.fullName}
          >
            <Td
              isTruncated
              maxWidth="150px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Flex align={"center"}>
                <Avatar
                  mr="10px"
                  size={"sm"}
                  src={datum?.userId?.image || datum?.userId?.[0]?.image}
                  alt={datum?.userId?.fullName || datum?.userId?.[0]?.fullName}
                  name={datum?.userId?.fullName || datum?.userId?.[0]?.fullName}
                />
                <Text isTruncated maxWidth="150px !important">
                  {datum?.userId?.fullName || datum?.userId?.[0]?.fullName}
                </Text>
              </Flex>
            </Td>
          </Tooltip>
          <Tooltip label={formatToNaira(datum?.amount)}>
            <Td
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.amount && formatToNaira(datum?.amount)}
            </Td>
          </Tooltip>
          <Tooltip label={dayjs(datum?.createdAt).format("DD MMM YYYY")}>
            <Td
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {dayjs(datum?.createdAt).format("MMM, DD YYYY")}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.loanCategory}>
            <Td
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.loanCategory}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.agreedLoanDetails?.userId?.fullName}>
            <Td
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.agreedLoanDetails?.userId?.[0]?.fullName || "N/A"}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.guarantorVerification}>
            <Td
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Text 
              bg={
                  datum?.guarantorVerification === false
                    ? "#FBF3D4"
                    : datum?.guarantorVerification === true
                    ? "green.300"
                    : "red"
                }
                color={
                  datum?.guarantorVerification === false
                    ? "#C9A721"
                    : "#FFFFFF"
                }
                textAlign={"center"}
                p="2px 5px"
                borderRadius={"10px"}
                >
                {datum?.guarantorVerification===true?"verified":"un-verified" || "N/A"}
              </Text>
              
            </Td>
          </Tooltip>
          {/* <Tooltip label={formatToNaira(datum?.proposals?.[0]?.amount)}>
            <Td
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {formatToNaira(datum?.proposals?.[0]?.amount) || "N/A"}
            </Td>
          </Tooltip> */}
           <Tooltip label={datum?.lenderTransfer}>
            <Td
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Text 
              bg={
                  datum?.lenderTransfer === false
                    ? "#FBF3D4"
                    : datum?.lenderTransfer === true
                    ? "green.300"
                    : "red"
                }
                color={
                  datum?.lenderTransfer=== false
                    ? "#C9A721"
                    : "#FFFFFF"
                }
                textAlign={"center"}
                p="2px 5px"
                borderRadius={"10px"}
                >
                {datum?.lenderTransfer===true?"funded":"pending" || "N/A"}
              </Text>
              
            </Td>
          </Tooltip>
          <Tooltip label={dayjs(datum?.disbursedDate).format("MMM, DD YYYY")}>
            <Td
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {/* {datum?.loanDisbursed===false?"Not yet disbursed":" loan disbursed" || "N/A"} */}
              {datum?.loanDisbursed
                ? dayjs(datum?.disbursedDate).format("MMM, DD YYYY")
                : "Not yet disbursed"}
            </Td>
          </Tooltip>
          
          {/* <Tooltip label={dayjs(datum?.dueDate).format("MMM, DD YYYY")}>
            <Td
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.dueDate && dayjs(datum?.dueDate).format("MMM, DD YYYY") }
            </Td>
          </Tooltip> */}
          
          <Tooltip label={datum?.directDebitDetails?.active===true?"Consented":"Not-consented"}>
            <Td
              color="#C9A721"
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Text
                bg={
                  datum?.directDebitDetails?.active
                    ? "green.300" : "#FBF3D4"
                    // : datum?.directDebitDetails?.active === true
                    // ? "green.300"
                    // : "red"
                }
                color={
                  datum?.directDebitDetails?.active
                    ? "#FFFFFF"
                    : "##C9A721"
                }
                p="2px 10px"
                borderRadius={"10px"}
              >
                {datum?.directDebitDetails?.active===true?"Consented":"Not-consented"}
              </Text>
            </Td>
          </Tooltip>



          <Tooltip label={datum?.status}>
            <Td
              color="#C9A721"
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Text
                bg={
                  datum?.status?.toLowerCase() === "pending"
                    ? "#FBF3D4"
                    : datum?.status?.toLowerCase() === "approved"
                    ? "green.300"
                    : "red"
                }
                color={
                  datum?.status?.toLowerCase() === "pending"
                    ? "#C9A721"
                    : "#FFFFFF"
                }
                p="2px 10px"
                borderRadius={"10px"}
              >
                {datum?.status}
              </Text>
            </Td>
          </Tooltip>
          <Tooltip label={datum?.transferStatus}>
            <Td
              color="#C9A721"
              isTruncated
              width="170px !important"
              px="10px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Text
                bg={
                  datum?.transferStatus?.toLowerCase() === "pending"
                    ? "#FBF3D4"
                    : datum?.transferStatus?.toLowerCase() === "success"
                    ? "green.300"
                    : "#FBF3D4"
                }
                color={
                  datum?.transferStatus?.toLowerCase() === "pending"
                    ? "#C9A721"
                    :datum?.transferStatus?.toLowerCase()==="success"
                    ? "#FFF"
                    : "#C9A721"
                }
                p="2px 5px"
                textAlign={"center"}
                borderRadius={"10px"}
              >
                {datum?.transferStatus==="success"?"Disbursed":"Not-disbursed"}
              </Text>
            </Td>
          </Tooltip>
          <Td isTruncated width="170px !important" px="10px !important">
            <Flex justify={"center"} gap={"10px"}>
              {/* <CustomModal header={"Verify Loan"} icon={<BsThreeDots color="#5E6CE7" />}>
                <ApproveLoan
                  datum={datum}
                  onClose={onClose}
                  setRefresh={setRefresh}
                />
              </CustomModal> */}
                  <Text cursor={"pointer"}>
                    <MoreActionButton
                      setRefresh={setRefresh}
                      onClose={onClose}
                      clickRef={clickRef}
                      datum={datum}
                      // status={request?.status}
                    />
                  </Text>



              <CustomDrawer
                icon={AiOutlineEye}
                header={"Loan Report Details"}
                iconColor={"#5E6CE7"}
                
                data={data}
              >
                <LoanReportsDrawerContent
                  datum={datum}
                  setRefresh={setRefresh}
                />
              </CustomDrawer>
            </Flex>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default LoanReportsTableBody;
