import axios from "axios";

export * from "./routes.constants";

export const baseURL = "https://backend.whouepp.com/api/v1";
const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Headers": "Content-Type",
  },
  // headers: {
  //   Accept: "application/json, text/plain, */*",
  //   "Content-Type": "multipart/form-data",
  //   // "Access-Control-Allow-Headers": "Content-Type",
  // },
});

const addTokenToRequest = async (req) => {
  const token = sessionStorage.getItem("#WUEPP#");
  req.headers.Authorization = `Bearer ${token}`;
  return req;
};

axiosInstance.interceptors.request.use(addTokenToRequest);

export default axiosInstance;
