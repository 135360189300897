import { useEffect, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import FilterSection from "../components/FilterSection";
import LoanReportsTab from "../components/tabs/LoanReportsTab";
import {
  getApprovedLoanReports,
  getDueLoanReports,
  getLoanReports,
  getPendingLoanReports,
  searchAllLoanReports,
  searchLoanReportsByDates,
  getLoanDetails,
} from "../services/loanReports";
import { loanReportsTableHead, loanReportsTabTitles, DisbursalTableHead} from "../table.constants";
import FullPageLoader from "../components/fullPageLoader";
import { Pagination } from "../components/Pagination";
import PageHeader from "../components/PageHeader";

const LoanReports = () => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [allLoanReports, setAllLoanReports] = useState([]);
  const [pendingLoanReports, setPendingLoanReports] = useState([]);
  const [dueLoanReports, setDueLoanReports] = useState([]);
  const [approvedLoanReports, setApprovedLoanReports] = useState([]);
  const [foundLoans, setFoundLoans] = useState([]);
  const [loanDetails, setLoanDetails] = useState([]);
  const [tabName, setTabName] = useState("All Status");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage] = useState(20);

  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;

  const dataByTabName =
    tabName === "Pending"
      ? pendingLoanReports
      : tabName === "Approved"
      ? approvedLoanReports
      : tabName === "Expired"
      ? approvedLoanReports
      : tabName === "Disbursal"
      ? dueLoanReports
      : allLoanReports;
  const users = foundLoans.length > 0 ? foundLoans : dataByTabName;

  const currentRecord = users?.slice(indexOfFirstRecord, indexOfLastRecord);
  const total =
    recordPerPage * currentPage + currentRecord.length - recordPerPage;
 const loanId = "64246f24bd386c1bbf23010e"
 console.log(allLoanReports,"MYrECORD")
 const approved = allLoanReports.filter((approve)=>approve?.status==="approved")
 console.log(approved,"REAL")
  useEffect(() => {
    getLoanReports(setAllLoanReports, setLoading);
    getPendingLoanReports(setPendingLoanReports, setLoading);
    getApprovedLoanReports(setApprovedLoanReports, setLoading);
    getDueLoanReports(setDueLoanReports, setLoading);
    getLoanDetails(setLoanDetails,setLoading,loanId)
  }, [refresh]);

  const handleSearch = (e) => {
    searchAllLoanReports(setFoundLoans, setLoading, e.target.value, tabName);
  };

  const handleSearchByDate = (e) => {
    const status =
      tabName === "Pending"
        ? "pending"
        : tabName === "Approved"
        ? "approved"
        : tabName === "Expired"
        ? "due"
        : undefined;

    searchLoanReportsByDates(
      setFoundLoans,
      setLoading,
      startDate,
      e.target.value,
      status && status,
      status
    );
  };

  console.log(allLoanReports, "all")
  console.log(loanDetails, "Tall")

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <PageHeader title="Loan Reports" />
      <Box>
        <FilterSection
          usersData={allLoanReports}
          tabName={tabName}
          pending={pendingLoanReports}
          approvedLoanReports={approvedLoanReports}
          expiredLoanReports={dueLoanReports}
          setFoundLoans={setFoundLoans}
          handleSearch={handleSearch}
          handleSearchByDate={handleSearchByDate}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </Box>
      <Box bg={"#fff"} borderRadius="10px" mt="20px" width={"100%"}>
        <LoanReportsTab
          setTabName={setTabName}
          allStatus={currentRecord}
          approvedData= {approved}
          tabHeadings={loanReportsTabTitles}
          tableHeadData={loanReportsTableHead}
          disbursalHeadData={DisbursalTableHead}
          setFoundLoans={setFoundLoans}
          setRefresh={setRefresh}
        />
      </Box>
      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {total} of {users.length} Users
        </Text>
        <Pagination
          totalData={users.length ?? 0}
          dataPerPage={recordPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};

export default LoanReports;
