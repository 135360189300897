import React, { useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineDelete } from "react-icons/ai";
import { deleteAdmin } from "../../services/admin";

export const DeleteAdminDrawer = ({ setRefresh, data }) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const handledelete = () => {
    setLoading(true);
    deleteAdmin(setLoading, setRefresh, data?._id, onClose);
  };

  return (
    <>
      <AiOutlineDelete color="#5E6CE7" onClick={onOpen} />
      <Drawer
        size={"sm"}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <Flex
            width="100%"
            align={"center"}
            justify={"space-between"}
            boxShadow="md"
            p="20px 30px !important"
          >
            <Text fontSize={"20px"} color={"#032A34"} fontWeight="semibold">
              Delete Existing Admin
            </Text>
          </Flex>

          <DrawerBody p="20px 30px !important">
            <Flex justify={"center"} align={"center"} w={"100%"} mt={"100px"}>
              <Text color={"#373737"} fontWeight={"semibold"} fontSize={"18px"}>
                Are you sure you want to delete this admin?
              </Text>
            </Flex>

            <Flex mt={["30px", "30px", "50px"]} justify={"center"} gap={"20px"}>
              <Button
                border={"2px solid #5E6CE7"}
                color={"#5E6CE7"}
                borderRadius="25px"
                w="150px"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                bg="#5E6CE7"
                color={"#fff"}
                borderRadius="25px"
                w="150px"
                isLoading={loading}
                _hover={{
                  bg: "#5E6CE7",
                }}
                onClick={handledelete}
              >
                Delete Admin
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
