import { Box } from "@chakra-ui/react";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { Suspense, useEffect } from "react";
import "./App.css";
import AuthenticatedRoutes from "./components/AuthenticatedRoutes";
import FullPageLoader from "./components/fullPageLoader";
import UnAuthenticatedRoutes from "./components/UnAuthenticatedRoutes";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem("#WUEPP#");
    if (token) {
      const decoded = jwtDecode(token);
      const expiryDate = new Date(decoded.exp * 1000);
      if (new Date() > expiryDate) {
        sessionStorage.removeItem("#WUEPP#");
      } 
      else {
        setIsLoggedIn(true);
      }
    }

    setCheckingStatus(false);
  }, []);

  if (checkingStatus) {
    return <FullPageLoader />;
  }

  if (isLoggedIn) {
    return <AuthenticatedRoutes />;
  }
  return (
    <Box className="App">
      <Suspense fallback={<p></p>}>
        <UnAuthenticatedRoutes />
      </Suspense>
    </Box>
  );
}

export default App;
