import React, { useState } from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { FormInputs } from "../FormInput";
import { updateProfile } from "../../services/accountSettings";

export const UserInfo = ({ userInfo, setRefresh }) => {
  const [formValues, setFormValues] = useState(() => ({
    name: userInfo?.name,
    email: userInfo?.email,
  }));

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    setLoading(true);
    const payload = {
      ...formValues,
      userId: userInfo?._id,
    };
    await updateProfile(payload, setLoading, setRefresh);
  };

  return (
    <Box
      gap="26px"
      align="center"
      w="100%"
      bg="#fff"
      py="19px"
      px="33px"
      my="15px"
      borderRadius="10px"
    >
      <Flex
        gap={["10px", "10px", "59px"]}
        direction={["column", "column", "row"]}
      >
        <FormInputs
          name={"name"}
          type={"text"}
          label={"Full Name"}
          value={formValues?.name}
          handleChange={handleChange}
        />
        <FormInputs
          name={"email"}
          type={"email"}
          label={"Email"}
          value={formValues?.email}
          handleChange={handleChange}
        />
      </Flex>
      <Flex justify="flex-end">
        <Button
          colorScheme="blue"
          borderRadius="24px"
          mt="34px"
          py="12px"
          px="28px"
          isLoading={loading}
          onClick={handleUpdate}
        >
          Update
        </Button>
      </Flex>
    </Box>
  );
};
