import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { BannerSecton } from "../components/accountSettings/BannerSecton";
import { UserInfo } from "../components/accountSettings/UserInfo";
import { UpdatePassword } from "../components/accountSettings/UpdatePassword";
import FullPageLoader from "../components/fullPageLoader";
import { getUserProfile } from "../contexts/getUserProfile";
import PageHeader from "../components/PageHeader";

const AccountSettings = () => {

  // console.log(state)
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getUserProfile(setData, setLoading, setRefresh);
  }, [refresh]);

  // console.log("Page Updated", data);
  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <PageHeader title="Account Settings" />
      <BannerSecton userInfo={data?.userInfo} setRefresh={setRefresh} />
      <UserInfo userInfo={data?.userInfo} setRefresh={setRefresh} />
      <UpdatePassword userInfo={data?.userInfo} setRefresh={setRefresh} />
    </Box>
  );
};

export default AccountSettings;
