import { TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import CustomTable from "../table/CustomTable";
import TableBody from "../table/TableBody";
import TabHeadings from "./TabHeadings";

const CustomTab = ({
  tabHeadings,
  tableHeadData,
  data,
  setTabName,
  handleUpdate,
  setFoundUsers,
  btnLoading,
  setRefresh
}) => {
  return (
    <Tabs width="100%">
      <TabHeadings
        tabHeadings={tabHeadings}
        setTabName={setTabName}
        setFoundUsers={setFoundUsers}
      />

      <TabPanels width="100% !important">
        <TabPanel width="100% !important" p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <TableBody
              data={data}
              handleUpdate={handleUpdate}
              setRefresh={setRefresh}
              // setLoading={setLoading}
              btnLoading={btnLoading}
              all={true}
            />
          </CustomTable>
        </TabPanel>
        <TabPanel p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <TableBody
              data={data}
              handleUpdate={handleUpdate}
              setRefresh={setRefresh}
              // setLoading={setLoading}
              btnLoading={btnLoading}
              status={"Suspended"}
            />
          </CustomTable>
        </TabPanel>
        <TabPanel p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <TableBody
              data={data}
              setRefresh={setRefresh}
              btnLoading={btnLoading}
            />
          </CustomTable>
        </TabPanel>

        <TabPanel p="0 !important">
          <CustomTable tableHeadData={tableHeadData}>
            <TableBody
              data={data}
              handleUpdate={handleUpdate}
              setRefresh={setRefresh}
              // setLoading={setLoading}
              btnLoading={btnLoading}
              status={"Active"}
            />
          </CustomTable>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default CustomTab;
