import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import MessageBox from "../components/messageInfo/MessageBox";
import MessageUserForm from "../components/messageInfo/MessageUserForm";
import { useGetState } from "../contexts/useGetState";
import FullPageLoader from "../components/fullPageLoader";
import PageHeader from "../components/PageHeader";

const Messages = () => {
  const { usersData } = useGetState();
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [recipient, setRecipient] = useState([]);
  const [types, setTypes] = useState([]);


  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width="100%">
      <PageHeader title="Message" />
      <Flex
        justify="space-between"
        w="100%"
        direction={["column", "column", "row"]}
      >
        <Box w={["100%", "100%", "64%"]}>
          <MessageBox
            recipient={recipient}
            setTypes={setTypes}
            types={types}
            selectedItems={selectedItems}
          />
        </Box>
        <Box w={["100%", "100%", "35%"]} mt={["20px", "20px", "0px"]}>
          <MessageUserForm
            usersData={usersData}
            setRecipient={setRecipient}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            setLoading={setLoading}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default Messages;
