import { Avatar, Flex, Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";
import { AiOutlineEye } from "react-icons/ai";
import CustomDrawer from "../../Drawer";
import LendersDrawerContent from "../../drawerChildren/LendersDrawerContent";

const DashboardTableBody = ({ data, handleUpdate, setRefresh, btnLoading }) => {
  return (
    <Tbody>
      {data?.map((data, key) => (
        <Tr key={key} fontSize={"16px"}>
          <Tooltip label={data?.fullName}>
            <Td
              isTruncated
              width="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Flex align={"center"}>
                <Avatar
                  mr="10px"
                  size={"sm"}
                  src=""
                  alt={data?.fullName}
                  name={data?.fullName}
                />
                <Text textTransform={"capitalize"}>{data?.fullName}</Text>
              </Flex>
            </Td>
          </Tooltip>
          <Tooltip label={data?.email}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {data?.email}
            </Td>
          </Tooltip>

          <Td borderRight="1px solid #dadedf !important">
            {data?.phoneNumber}
          </Td>
          <Td borderRight="1px solid #dadedf !important">
            {dayjs(data?.createdAt).format("DD MMM YYYY")}
          </Td>
          <Td
            textTransform={"capitalize"}
            borderRight="1px solid #dadedf !important"
          >
            {data?.accountType}
          </Td>

          <Td color="#C9A721" borderRight="1px solid #dadedf !important">
            <Text
              textAlign={"center"}
              bg={
                data?.status?.toLowerCase() === "pending"
                  ? "#FBF3D4"
                  : data?.status?.toLowerCase() === "active"
                  ? "green.300"
                  : data?.status?.toLowerCase() === "unverified"
                  ? "pink.300"
                  : "red"
              }
              color={
                data?.status?.toLowerCase() === "pending"
                  ? "#C9A721"
                  : "#FFFFFF"
              }
              p="2px 10px"
              borderRadius={"10px"}
            >
              {data?.status}
            </Text>
          </Td>
          <Td>
            <CustomDrawer
              icon={AiOutlineEye}
              header={`${data?.accountType} Details`}
              iconColor={"#5E6CE7"}
              data={data}
            >
              <LendersDrawerContent
                datum={data}
                handleUpdate={handleUpdate}
                setRefresh={setRefresh}
                btnLoading={btnLoading}
                status={
                  data?.status === "active"
                    ? "suspended"
                    : "active"
                }
              />
            </CustomDrawer>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default DashboardTableBody;
