import http, { AUTH_ROUTES } from "../services/api";

export const getUserProfile = async (setState, setLoading, setRefresh) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_LOGGED_IN_ADMIN);
    // console.log("data>>>>>>!!", data);


    setState((prev) => ({
      ...prev,
      userInfo: data,
    }));
    setRefresh((prev) => !prev)

    return data;
  } catch (e) {
    console.log(e, "here>>>>>>");
  } finally {
    setLoading(false)
  }
};
