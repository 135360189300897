import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import AmountCard from "../components/AmountCard";
import DashoardTable from "../components/table/dashboard/DashoardTable";
import GreetingsCard from "../components/GreetingsCard";
import { getRegisteredUsers } from "../services/dashboard";
import FullPageLoader from "../components/fullPageLoader";
// import { useGetState } from "../contexts/useGetState";
import { updateLenderStatus } from "../services/borrowersAndLenders";
import PageHeader from "../components/PageHeader";

const Dashboard = () => {
  // const { usersData } = useGetState();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getRegisteredUsers(setUsers, setLoading);
  }, [refresh]);

  const allLength = users.map((user) => {
    return user.accountType;
  });
  const lenderNumber = allLength.filter((userNum) => {
    return userNum === "lender";
  });
  const borrowerNumber = allLength.filter((userNum) => {
    return userNum === "borrower";
  });
  const handleUpdate = async (id, status, onClose) => {
    setBtnLoading(true);
    await updateLenderStatus(id, status, setBtnLoading, setRefresh, onClose);
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width={"100%"}>
      <PageHeader title="Dashboard" />
      <Flex
        width={"100%"}
        justifyContent="space-between"
        direction={["column", "column", "row"]}
      >
        <Box width={["100%", "100%", "56%"]}>
          <GreetingsCard />
        </Box>
        <Box width={["100%", "100%", "20%"]}>
          <AmountCard
            title="Total Lenders"
            amount={lenderNumber.length}
          />
        </Box>
        <Box width={["100%", "100%", "20%"]}>
          <AmountCard
            bg="#D8DBFB"
            color={"#5E6CE7"}
            title="Total Borrowers"
            amount={borrowerNumber.length}
          />
        </Box>
      </Flex>
      <Box my="30px">
        <Text color="#090110" mb="10px" fontWeight={"semibold"}>
          Recent Registrations
        </Text>
        <DashoardTable
          users={users?.slice(0)}
          handleUpdate={handleUpdate}
          setRefresh={setRefresh}
          btnLoading={btnLoading}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
