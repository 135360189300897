import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Stack,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { formatToNaira } from "../../utils/numberFormat";

const LendersDrawerContent = ({
  datum,
  btnLoading,
  handleUpdate,
  status,
  onClose,
}) => {

  const btnText = status === "active" ? "approve" : "suspend";
  console.log(status, "showw", datum)

  return (
    <>
      <Flex
        width="100%"
        justify={"space-between"}
        mb="20px"
        mt="40px"
        px="30px !important"
      >
        <Text color="#373737" fontWeight={"semibold"}>
          Personal Information
        </Text>
        <Text
          bg={
            datum?.status?.toLowerCase() === "pending"
              ? "#FBF3D4"
              : datum?.status?.toLowerCase() === "active"
              ? "green.300"
              : datum?.status?.toLowerCase() === "unverified"
              ? "pink.300"
              : "red"
          }
          color={
            datum?.status?.toLowerCase() === "pending" ? "#C9A721" : "#FFFFFF"
          }
          p="2px 10px"
          borderRadius={"10px"}
        >
          {datum?.status}
        </Text>
      </Flex>
      <Flex bg="#F8F8F8" p="20px 30px" width="100%" justify="space-between">
        <Flex>
          <Avatar
            width="65px"
            height="65px"
            name={datum?.image || datum?.fullName}
            src={datum?.image}
            alt="Avatar"
          />
          <Box ml="15px">
            <Text
              color="#373737"
              fontWeight={"semibold"}
              textTransform={"capitalize"}
            >
              {datum?.fullName}
            </Text>
            <Text color="#373737" fontSize={"14px"} fontWeight={"normal"}>
              {datum?.email}
            </Text>
            <Text color="#373737" fontSize={"14px"} fontWeight={"normal"}>
              {datum?.phoneNumber}
            </Text>
          </Box>
        </Flex>
        <Flex>
          <Divider orientation="vertical" />
          <Box ml="10px" fontSize={"14px"}>
            <Text>{dayjs(datum?.createdAt).format("MMMM DD YYYY")}</Text>
            <Text>{datum?.address}</Text>
            <Text>{datum?.maritalStatus}</Text>
          </Box>
        </Flex>
      </Flex>
      <Box p="30px" bg="#fff">
        <Text color="#373737" fontWeight={"semibold"}>
          Bank Information 
        </Text>
      </Box>
      <Flex
        width="100%"
        justify={"space-between"}
        p="20px 30px"
        bg="#F8F8F8"
        fontSize={"14px"}
      >
        <Stack color="#B9B7B7">
          <Text>Account Name</Text>
          <Text>Bank Name</Text>
          <Text>Account Number</Text>
          <Text>BVN Number</Text>
          <Text>Card Number</Text>
          <Text>Expiry Date</Text>
          <Text>CVC Number</Text>
          <Text>Card Type</Text>
        </Stack>
        <Stack color="#373737">
          <Text>{datum?.fullName || "N/A"}</Text>
          <Text>{datum?.cards?.bank || "N/A"}</Text>
          <Text>{datum?.cards?.accountNumber || "N/A"}</Text>
          <Text>{datum?.cards?.bin || "N/A"}</Text>
          <Text>{datum?.cards?.cardNumber || "N/A"}</Text>
          <Text>{datum?.cards?.cardExpiryYear || "N/A"}</Text>
          <Text>{datum?.cards?.cvc || "N/A"}</Text>
          <Text>{datum?.cards?.cardType || "N/A"}</Text>
        </Stack>
      </Flex>
      <Box p="30px" bg="#fff">
        <Text color="#373737" fontWeight={"semibold"}>
          Employment Information
        </Text>
      </Box>
      <Flex
        width="100%"
        justify={"space-between"}
        p="20px 30px"
        bg="#F8F8F8"
        fontSize={"14px"}
      >
        <Stack color="#B9B7B7">
          <Text>Employment Status</Text>
          <Text>Company Name</Text>
          <Text>Registration No</Text>
          <Text>Company Address</Text>
          <Text>Company Email</Text>
          <Text>Annual Income</Text>
        </Stack>
        <Stack color="#373737">
          <Text>{datum?.employmentDetails?.employmentStatus}</Text>
          <Text>{datum?.employmentDetails?.companyName}</Text>
          <Text>{datum?.employmentDetails?.regNo || "N/A"}</Text>
          <Text>{datum?.employmentDetails?.address || "N/A"}</Text>
          <Text>{datum?.employmentDetails?.workEmail}</Text>
          <Text>{formatToNaira(datum?.employmentDetails?.annualIncome)}</Text>
        </Stack>
      </Flex>

      <Box p="30px" Bg="#fff">
        <Text color="#373737" fontWeight={"semibold"}>
          Guarantor's Information
        </Text>
      </Box>
      {datum?.guarantors?.map((guarantor) => (
        <Flex
          key={guarantor?._id}
          width="100%"
          justify={"space-between"}
          p="20px 30px"
          bg="#F8F8F8"
          fontSize={"14px"}
        >
          <Stack color="#B9B7B7" width="50%">
            <Text>Name</Text>
            <Text>Address</Text>
            <Text>Phone Number</Text>
            <Text>Email</Text>
            <Text>Status</Text>
          </Stack>
          <Stack
            color="#373737"
            width={["70%", "70%", "60%", "45%"]}
            justify={"flex-end"}
          >
            <Text>{guarantor?.fullName || "N/A"}</Text>
            <Text>{guarantor?.address || "N/A"}</Text>
            <Text>{guarantor?.phoneNumber || "N/A"}</Text>
            <Text>{guarantor?.email || "N/A"}</Text>
            <Text>{guarantor?.isVerified ? "Verified" : "Not Verified"}</Text>
          </Stack>
        </Flex>
      ))}
      {/*<Box p="30px" Bg="#fff">
        <Text color="#373737" fontWeight={"semibold"}>
          Lending Preference
        </Text>
      </Box>
      <Flex
        width="100%"
        justify={"space-between"}
        p="20px 30px"
        bg="#F8F8F8"
        fontSize={"14px"}
      >
        <Stack color="#B9B7B7">
          <Text>Minimum Individual Loan</Text>
          <Text>Maximum Individual Loan</Text>
          <Text>Minimum Loan Interest</Text>
          <Text>Maximum Loan Interest</Text>
          <Text>Preferred Loaning Location</Text>
          <Text>Borrowers Employment Category</Text>
          <Text>Daily Loan Offers</Text>
        </Stack>
        <Stack color="#373737">
          <Text>
            {formatToNaira(datum?.loanPreference?.minimumLoan) || "N/A"}
          </Text>
          <Text>
            {formatToNaira(datum?.loanPreference?.maximumLoan) || "N/A"}
          </Text>
          <Text>
            {formatToNaira(datum?.loanPreference?.minimumInterest) || "N/A"}
          </Text>
          <Text>
            {formatToNaira(datum?.loanPreference?.maximumInterest) || "N/A"}
          </Text>
          <Text>N/A</Text>
          <Text>N/A</Text>
          <Text>N/A</Text>
        </Stack>
      </Flex>*/}
      <Flex p="30px" width={"100%"} justify="flex-end">
        <Button
          _hover={{ bg: status === "active" ? "#5E6CE7" : "red" }}
          p="20px 30px"
          bg={status === "active" ? "#5E6CE7" : "red"}
          borderRadius={"25px"}
          color="#fff"
          onClick={() => handleUpdate(datum?._id, status, onClose)}
          isLoading={btnLoading}
        >
          {`${btnText} ${datum?.accountType}`}
        </Button>
      </Flex>
    </>
  );
};

export default LendersDrawerContent;
