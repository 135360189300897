import React from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Children } from "react";
import { isValidElement } from "react";
import { cloneElement } from "react";

const CustomDrawer = ({
  children,
  icon: Icon,
  button,
  header,
  iconColor,
  iconSize,
  data,
  title,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { onClose });
    }

    return child;
  });

  return (
    <>
      {!button && (
        <Flex cursor="pointer" onClick={onOpen} align="center">
          <Icon color={iconColor} size={iconSize || 18} />
          <Text ml="10px">{title}</Text>
        </Flex>
      )}
      {button && (
        <Button
          _hover={{ bg: "#5E6CE7" }}
          p="20px 30px"
          bg="#5E6CE7"
          borderRadius={"25px"}
          color="#fff"
          onClick={onOpen}
        >
          Message Borrower
        </Button>
      )}
      <Drawer
        size={"md"}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <Flex
            width="100%"
            align={"center"}
            justify={"space-between"}
            boxShadow="md"
            p="20px 30px !important"
          >
            <Text
              fontSize={"18px"}
              color={"#1A0031"}
              fontWeight="semibold"
              textTransform={"capitalize"}
            >
              {header}
            </Text>
            {title !== "Notifications" ? (
              <Button
                bg="#5E6CE7"
                color={"#fff"}
                borderRadius="25px"
                ref={btnRef}
                onClick={onClose}
                _hover={{
                  bg: "#5E6CE7",
                }}
              >
                Cancel
              </Button>
            ) : (
              <DrawerCloseButton />
            )}
          </Flex>

          <DrawerBody p="0px !important">{childrenWithProps}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CustomDrawer;
