import React from "react";
import { Flex, Checkbox } from "@chakra-ui/react";
import { useState } from "react";

const checkboxes = [
  { name: "All", value: "all", isChecked: false },
  { name: "In App", value: "inApp", isChecked: false },
  { name: "Email", value: "email", isChecked: false },
  { name: "SMS", value: "sms", isChecked: false },
];

function MessageCheckbox({ setTypes }) {
  const [checkedItems, setCheckedItems] = useState({});
    const handleCheckboxChange = (value) => {
    if (value === "all") {
      const newCheckedItems = {};
      checkboxes.forEach((item) => {
        newCheckedItems[item.value] = !checkedItems[value];
      });
      setCheckedItems(newCheckedItems);
      if (!checkedItems[value]) {
        setTypes(
          checkboxes
            .filter((item) => item.value !== value)
            .map((item) => item.value)
        );
      } else {
        setTypes([]);
      }
    } else {
      setCheckedItems({
        ...checkedItems,
        [value]: !checkedItems[value],
      });
      setTypes((prevSelectedItems) => {
        if (checkedItems[value]) {
          return prevSelectedItems.filter((item) => item !== value);
        } else {
          return [...prevSelectedItems, value];
        }
      });
    }
    
  };

  return (
    <Flex
      width={"100%"}
      mb="20px"
      bg="#F1F2FF"
      py="21px"
      px="14px"
      borderRadius="6px"
    >
      {checkboxes.map((item, key) => {
        return (
          <Flex key={key} mr="20px">
            <Checkbox
              name={item.name}
              mr=""
              type="checkbox"
              borderColor={"#c4c4c4"}
              size={"lg"}
              value={item.value}
              isChecked={checkedItems[item.value]}
              onChange={(e) => {
                handleCheckboxChange(item.value);
              }}
            >
              {item.name}
            </Checkbox>
          </Flex>
        );
      })}
    </Flex>
  );
}

export default MessageCheckbox;
